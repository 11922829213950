/* eslint-disable no-console */
import React, { Component } from 'react';
import axios from 'axios';
import FilterableTable from 'rc-data-table';
import { AuthService } from '../../../services/authentication.service';
import Button from '../../../components/common/button';
import settings from '../../../constants/constants';
import CircleLoader from '../../../components/common/circleLoader';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CustomModal from '../../../components/common/CustomModal';
import DateRangeComp from '../../../components/daterange/DateRangeComp';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import { Logger } from '@/utils/logger';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import withRouter from '@/utils/withRouter';

const logger = new Logger('ViewTransaction - eWayTransaction');
class ViewTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      user_role_id: AuthService.getUser().user.role_id,
      pageSize: 100,
      count: 0,
      page: 0,
      filter: null,
      sort: { name: 'id', reverse: true },
      loading: true,
      fromDate: moment().startOf('day').toDate(),
      toDate: moment().endOf('day').toDate(),
      downloading: false,
      selectedItems: [],
      open: false,
      invoiceFilter: 1,
    };
  }

  async componentDidMount() {
    document.title = 'Patients - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
  }

  debouncedSearchInvoices = AwesomeDebouncePromise((page, pageSize, sort, filter, fromDate, toDate, invoiceFilter) => {
    return axios.get(`${settings.url}/order/v1/getEWayOrders`, {
      params: {
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        limit: pageSize,
        offset: page * pageSize,
        sort: sort.name,
        sortOrder: sort.reverse ? 'DESC' : 'ASC',
        filter,
        ts: new Date().getTime(),
        invoiceFilter: invoiceFilter,
      },
    });
  }, 500);

  dataBind = async () => {
    const { page, pageSize, sort, filter, fromDate, toDate, invoiceFilter } = this.state;

    this.debouncedSearchInvoices(page, pageSize, sort, filter, fromDate, toDate, invoiceFilter).then((data) => {
      this.setState({ data: data.data.result, count: data.data.count, selectedItems: [], loading: false });
    });
  };

  pageChange = async (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (filter) => {
    this.setState({ filter, page: 0 }, function () {
      this.dataBind();
    });
  };

  searchOrders = async () => {
    this.dataBind();
  };

  openPdf = async (e, order_code) => {
    e.preventDefault();
    this.setState({ downloading: true });
    await axios({
      url: `${settings.url}/order/v1/getPatientReceiptPdf`,
      method: 'GET',
      params: {
        order_code: order_code,
      },
      responseType: 'blob',
    })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
        this.setState({ downloading: false });
      })
      .catch((err) => {
        toast.info('Tax invoice not available for this order.');
        logger.error('Tax invoice not available for this order.', err);
        this.setState({ downloading: false });
      });
  };

  //export as excel
  downloadFile = async () => {
    const { fromDate, toDate, invoiceFilter, selectedItems, filter } = this.state;
    await axios({
      url: 'excel/ewayOrders/download',
      method: 'POST',
      data: {
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
        invoiceFilter: invoiceFilter,
        selectedOrders: selectedItems,
        filter: filter,
        tz: moment().format('Z'),
      },
    }).then((response) => {
      toast.success(response.data.message);
    });
  };

  checkChange = (e) => {
    let value = parseInt(e.target.value);
    let { selectedItems } = this.state;
    if (e.target.checked) {
      selectedItems.push(value);
    } else {
      selectedItems = selectedItems.filter((x) => x !== value);
    }
    this.setState({
      selectedItems,
    });
  };

  selectAll = () => {
    if (this.state.selectedItems.length > 0) {
      this.setState({
        selectedItems: [],
      });
      return;
    }
    this.setState({
      selectedItems: this.state.data.map((x) => x.id),
    });
  };

  openDateRange = async (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  selectDate = async (from_date, to_date) => {
    this.setState(
      {
        fromDate: moment(from_date).startOf('day').toDate(),
        toDate: moment(to_date).endOf('day').toDate(),
      },
      function () {
        this.dataBind();
      },
    );
  };

  closeCalendar = () => {
    this.setState({
      open: false,
    });
  };

  setFilter = async (e) => {
    this.setState({ invoiceFilter: e.value, page: 0 }, function () {
      this.dataBind();
    });
  };

  render() {
    const { data, page, pageSize, sort, count, loading, filter, fromDate, toDate, selectedItems } = this.state;

    const fields = [
      { name: 'id', displayName: 'id', inputFilterable: true, sortable: true, visible: false },
      { name: 'transactionType', displayName: 'Source', tdClassName: 'sub-text' },
      {
        name: 'order_code',
        displayName: 'Order ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'order_code',
        render: (e) => (
          <Link
            onClick={(event) => this.openPdf(event, e.value)}
            style={{ pointerEvents: this.state.downloading ? 'none' : '' }}
          >
            {e.value}
          </Link>
        ),
      },
      {
        name: 'paid_date',
        displayName: 'Paid Date',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'paid_date',
      },
      {
        name: 'order_date',
        displayName: 'Order Date',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'order_date',
      },
      {
        name: 'patientName',
        displayName: 'Name',
        inputFilterable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'PatientUser.first_name',
      },
      {
        name: 'patientCode',
        displayName: 'Patient ID',
        inputFilterable: true,
        tdClassName: 'sub-text',
        render: (e) => <Link to={`/onboard/${e.record.patient_id}`}>{e.value}</Link>,
      },
      {
        name: 'orderValue',
        displayName: 'Order Value',
        thClassName: 'align-center',
        tdClassName: 'sub-text align-center',
        render: (e) => (
          <NumberFormat
            value={e.value}
            decimalScale={2}
            fixedDecimalScale={true}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
          />
        ),
      },
      {
        name: 'id',
        displayName: 'Select',
        thClassName: 'align-center',
        tdClassName: 'sub-text align-center',
        visible: AuthService.isSuperAdmin() ? true : false,
        render: (e) => {
          return (
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={selectedItems.includes(e.value)}
                onChange={this.checkChange}
                value={e.value}
                id={`check${e.value}`}
              />
              <label className="custom-control-label" htmlFor={`check${e.value}`}></label>
            </div>
          );
        },
      },
    ];

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '35px',
        border: 0,
      },
    };

    const filterOptions = [
      { label: 'All', value: 1 },
      { label: 'Invoices', value: 2 },
      { label: 'Payment Gateway', value: 3 },
      { label: 'Cancelled-Xero', value: 4 },
      { label: 'Cancelled-Payment Gateway', value: 5 },
    ];

    return (
      <div className="container-fluid">
        <h3 style={{ marginBottom: '20px' }}>Invoices</h3>
        <table style={{ display: 'initial' }}>
          <tr>
            <td>
              <div className="form-inline bulk-update mr-4">
                <Select
                  name="gpFilter"
                  placeholder="Filter"
                  className="select-field rounded"
                  defaultValue={filterOptions[0]}
                  options={filterOptions}
                  onChange={this.setFilter}
                  styles={{
                    control: (prev) => ({
                      ...prev,
                      border: '1px solid #cccccc',
                      height: '35px',
                      minHeight: '35px',
                    }),
                  }}
                />
              </div>
            </td>
            <td>
              <Button
                className="btn btn-md mr-4"
                style={{ border: '1px solid #cccccc' }}
                icon="fa fa-calendar"
                iconPosition="left"
                onClick={this.openDateRange}
                text={`${moment(fromDate).format('DD-MMM-YYYY')} - ${moment(toDate).format('DD-MMM-YYYY')}`}
              />
              {this.state.open && (
                <div className="invoice-search">
                  <DateRangeComp
                    open={true}
                    buttonName="Search"
                    closeCalendar={this.closeCalendar}
                    onChange={this.selectDate}
                  />{' '}
                </div>
              )}
            </td>
          </tr>
        </table>

        {AuthService.isSuperAdmin() ? (
          <Button
            text="Export All"
            className="btn btn-md btn-primary float-right mr-5"
            style={{ display: 'inline-block', 'margin-bottom': '20px' }}
            onClick={this.downloadFile}
            disabled={this.state.downloading}
          />
        ) : (
          ''
        )}

        {loading ? (
          <CircleLoader />
        ) : (
          <FilterableTable
            className="table-responsive"
            topPagerVisible={false}
            data={data}
            fields={fields}
            paginationOnServer={true}
            initialSort={sort.name}
            initialSortDir={!sort.reverse}
            onPageChanged={this.pageChange}
            filterData={this.onFilter}
            serverSort={this.getSortColumn}
            totalRecords={count}
            currentPage={page}
            pageSize={pageSize}
            filterPlaceholder={filter ? filter : 'Search'}
            pageSizes={false}
            maintainPageOnSetData={true}
            noRecordsMessage="There are no record to display"
            noFilteredRecordsMessage="No record match your filters!"
          />
        )}
        <CustomModal isOpen={this.state.downloading} style={customStyles} shouldCloseOnOverlayClick={false}>
          <CircleLoader style={{ margin: 0 }} />
        </CustomModal>
      </div>
    );
  }
}

export default withRouter(ViewTransaction);
