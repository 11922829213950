/* eslint-disable indent */
/* eslint-disable semi */
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import { AuthService } from '@/services/authentication.service';
import { getStates } from '@/services/data.service';

import '../../scss/patientRefill.scss';
import CircleLoader from '../common/circleLoader';
import DatePicker from 'react-datepicker';
import { Logger } from '@/utils/logger';
import USER_ROLES from '@/constants/userRoles';
import { UserService } from '@/services/user.service';
import { useNavigate } from 'react-router-dom';

const logger = new Logger('profileDetails');

const UserProfile = (props) => {
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    user: null,
    patient: null,
    GP: null,
    clinics: [],
    countries: [],
    loading: true,
    userAddress: { line1: '', line2: '' },
  });
  const [userStates, setUserStatues] = React.useState([]);
  const [clinicStatues, setClinicStates] = React.useState([]);
  const [clinic2States, setClinic2States] = React.useState([]);
  const [processLoader, setProcessLoader] = React.useState(false);
  const clinicDefault = {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    zip_code: '',
    country_id: '',
    state_id: '',
  };

  const [profileValidator, setValidations] = React.useState(new SimpleReactValidator());
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const user = AuthService.getUser()?.user;

  const loadData = React.useCallback(async () => {
    const resp = await UserService.fetchUserProfile();
    const userAddress = data.userAddress;
    if (resp.data.user.address) {
      const lineAddress = resp.data.user.address.split('$$$$');
      userAddress.line1 = lineAddress[0];
      userAddress.line2 = lineAddress.length > 1 ? lineAddress[1] : '';
    }
    if (resp.data.user.country_id && resp.data.user.state_id) {
      setUserStatues(resp.data.states.map((x) => ({ label: x.name, value: x.id })));
    }
    if (resp.data.GP) {
      if (resp.data.clinics.length === 0) {
        resp.data.clinics = [clinicDefault];
      }
      if (resp.data.clinics.length > 0 && resp.data.clinics[0].country_id) {
        getStates(resp.data.clinics[0].country_id).then((x) =>
          setClinicStates(x.map((s) => ({ label: s.name, value: s.id }))),
        );
      }
      if (resp.data.clinics.length > 1 && resp.data.clinics[1].country_id) {
        getStates(resp.data.clinics[1].country_id).then((x) =>
          setClinic2States(x.map((s) => ({ label: s.name, value: s.id }))),
        );
      }
    }
    setData({
      ...resp.data,
      loading: false,
      userAddress: userAddress,
      ...(resp.data.MLs && {
        MLs: resp.data.MLs.map((x) => ({ label: `${x.Liaison.first_name} ${x.Liaison.last_name}`, value: x.id })),
      }),
      countries: resp.data.countries.map((x) => ({ label: x.name, value: x.id })),
    });
  }, []);
  React.useEffect(() => {
    loadData();
  }, []);

  if (!user) {
    logger.error('User not found');
    return null;
  }

  const addClinic = () => {
    setData({ ...data, clinics: [...data.clinics, clinicDefault] });
  };

  const removeClinic = () => {
    setData({ ...data, clinics: [data.clinics.reverse().pop()] });
  };

  const handleClinicInput =
    (idx, prop, dropdown = false) =>
    (e) => {
      const clinics = data.clinics;
      clinics[idx][prop] = dropdown ? e.value : e.target.value;
      setData({ ...data, clinics });
      if (prop === 'country_id') {
        getStates(e.value).then((x) => {
          if (idx === 0) {
            setClinicStates(x.map((s) => ({ label: s.name, value: s.id })));
          } else {
            setClinic2States(x.map((s) => ({ label: s.name, value: s.id })));
          }
        });
      }
    };

  const changeCountry = (e) => {
    getStates(e.value).then((x) => setUserStatues(x.map((s) => ({ label: s.name, value: s.id }))));
    setData({ ...data, user: { ...data.user, country_id: e.value } });
  };

  const handleSubmit = async () => {
    if (profileValidator.allValid()) {
      setProcessLoader(true);
      const context = {
        patient: data.patient,
        GP: data.GP,
        clinics: data.clinics,
        user: { ...data.user, address: `${data.userAddress.line1}$$$$${data.userAddress.line2}` },
      };
      try {
        const resp = await UserService.updateUserProfile(context);
        if (resp.status !== 200) {
          throw new Error(resp.status);
        }
        toast.success('Profile Details Updated.');
        AuthService.updateUser(context.user);
        navigate(user.role_id === USER_ROLES.patient ? '/home' : '/orders');
      } catch (e) {
        toast.error('Profile Details not updated. Please try again later.');
        logger.error('Profile Details not updated. Please try again later.', e);
        setProcessLoader(false);
      }
    }
    profileValidator.showMessages();
    setValidations(profileValidator);
    forceUpdate();
  };

  return (
    <React.Fragment>
      {data.loading ? (
        <CircleLoader />
      ) : (
        <div className={user.role_id === USER_ROLES.patient ? 'container pt-140' : 'container'}>
          <div className="container">
            <div className="d-flex flex-column">
              <div className={user.role_id === USER_ROLES.patient ? 'profile-container' : ''}>
                <h3 className="label">Profile Details</h3>
                <div className="d-flex flex-column">
                  <div className="form-row">
                    <FormInput
                      type="text"
                      className={`col-sm-12 ${data.patient ? 'col-md-4' : 'col-md-6'}`}
                      name="firstName"
                      label="First Name"
                      value={data.user.first_name || ''}
                      readOnly
                    />
                    <FormInput
                      type="text"
                      className={`col-sm-12 ${data.patient ? 'col-md-4' : 'col-md-6'}`}
                      name="lastName"
                      label="Last Name"
                      value={data.user.last_name || ''}
                      readOnly
                    />
                    {data.patient && (
                      <div className="flex-column d-flex col-sm-12 col-md-4">
                        <label className="label">Date of Birth</label>
                        <DatePicker
                          className="form-control"
                          id="stockReturnDate"
                          selected={data.patient.dob ? new Date(data.patient.dob) : ''}
                          name="patient-dob"
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          dropdownMode="select"
                          disabled
                        />
                      </div>
                    )}
                  </div>
                  <div className="form-row">
                    <FormInput
                      type="text"
                      className="col-sm-12 col-md-6"
                      name="email"
                      label="Email"
                      value={data.user.email || ''}
                      disabled={true}
                    />
                    <FormInput
                      type="text"
                      className="col-sm-12 col-md-6"
                      name="phone"
                      label="Phone"
                      value={data.user.phone || ''}
                      error={profileValidator.message('phone', data.user.phone, 'required')}
                      onChange={(e) => setData({ ...data, user: { ...data.user, phone: e.target.value } })}
                    />
                  </div>
                  {data.GP ? (
                    <>
                      <div className="form-row">
                        <FormInput
                          type="text"
                          className="col-sm-12 col-md-6"
                          name="aphraNo"
                          label="AHPRA Number"
                          value={data.GP.ahpra_no || ''}
                          disabled={true}
                          error={profileValidator.message('aphraNo', data.GP.ahpra_no, 'required')}
                          onChange={(e) => setData({ ...data, GP: { ...data.GP, ahpra_no: e.target.value } })}
                        />
                        <div className="col-sm-12 col-md-6">
                          <label>Business Development Manager</label>
                          <Select
                            name="medicalLiasion"
                            className={`${
                              profileValidator.message('MedicalLiasion', data.GP.ml_id, 'required')
                                ? 'rounded dropdown-border'
                                : ''
                            }`}
                            options={data.MLs}
                            value={data.MLs.find((x) => x.value === data.GP.ml_id) || ''}
                            onChange={(e) => setData({ ...data, GP: { ...data.GP, ml_id: e.value } })}
                            isSearchable={true}
                          />
                          {profileValidator.message('MedicalLiasion', data.GP.ml_id, 'required')}
                        </div>
                      </div>
                      <h3 className="mt-3 row">Clinic Details</h3>
                      <div className="container form-container">
                        {data.clinics.map((clinic, idx) => (
                          <div key={`clinic-${idx}`}>
                            {idx === 0 ? (
                              <h5>Primary Clinic</h5>
                            ) : (
                              <div className="justify-content-between d-flex">
                                <h5>Secondary Clinic</h5>
                                <Button
                                  text="x"
                                  className="btn btn-primary btn-md float-right"
                                  onClick={removeClinic}
                                />
                              </div>
                            )}
                            <div className="form-row">
                              <FormInput
                                type="text"
                                className="col-12"
                                name={`clinic-${idx}-name`}
                                label="Clinic Name"
                                value={clinic.name || ''}
                                error={profileValidator.message(`clinic-${idx}-name`, clinic.name, 'required')}
                                onChange={handleClinicInput(idx, 'name')}
                              />
                            </div>
                            <div className="form-row">
                              <FormInput
                                type="text"
                                className="col-sm-12 col-md-6"
                                name={`clinic-${idx}-email`}
                                label="Email"
                                value={clinic.email || ''}
                                onChange={handleClinicInput(idx, 'email')}
                                error={profileValidator.message(`clinic-${idx}-email`, clinic.email, 'required')}
                              />
                              <FormInput
                                type="text"
                                className="col-sm-12 col-md-6"
                                name={`clinic-${idx}-phone`}
                                label="Phone"
                                value={clinic.phone || ''}
                                error={profileValidator.message(`clinic-${idx}-phone`, clinic.phone, 'required')}
                                onChange={handleClinicInput(idx, 'phone')}
                              />
                            </div>
                            <div className="form-row">
                              <FormInput
                                type="text"
                                className="col-12"
                                name={`clinic-${idx}-address`}
                                label="Address"
                                value={clinic.address || ''}
                                info="Clinic Address"
                                rows={2}
                                onChange={handleClinicInput(idx, 'address')}
                                error={profileValidator.message(`clinic-${idx}-address`, clinic.address, 'required')}
                              />
                            </div>
                            <div className="form-row">
                              <FormInput
                                type="text"
                                className="col-sm-12 col-md-6"
                                name={`clinic-${idx}-city`}
                                label="City"
                                value={clinic.city || ''}
                                onChange={handleClinicInput(idx, 'city')}
                              />
                              <div className="col-sm-12 col-md-6">
                                <label>State</label>
                                <Select
                                  name={`clinic-${idx}-state`}
                                  className={`${
                                    profileValidator.message(`clinic-${idx}-state`, clinic.state_id, 'required')
                                      ? 'rounded dropdown-border'
                                      : ''
                                  }`}
                                  options={idx === 0 ? clinicStatues : clinic2States}
                                  value={
                                    (idx === 0 ? clinicStatues : clinic2States).find(
                                      (x) => x.value === clinic.state_id,
                                    ) || ''
                                  }
                                  onChange={handleClinicInput(idx, 'state_id', true)}
                                  isSearchable={true}
                                />
                                {profileValidator.message(`clinic-${idx}-state`, clinic.state_id, 'required')}
                              </div>
                            </div>
                            <div className="form-row">
                              <FormInput
                                type="text"
                                className="col-sm-12 col-md-6"
                                name={`clinic-${idx}-postCode`}
                                label="Postcode"
                                value={clinic.zip_code || ''}
                                onChange={handleClinicInput(idx, 'zip_code')}
                              />
                              <div className="col-sm-12 col-md-6">
                                <label>Country</label>
                                <Select
                                  name={`clinic-${idx}-country`}
                                  className={`${
                                    profileValidator.message(`clinic-${idx}-country`, clinic.country_id, 'required')
                                      ? 'rounded dropdown-border'
                                      : ''
                                  }`}
                                  options={data.countries}
                                  value={data.countries.find((x) => x.value === clinic.country_id) || ''}
                                  onChange={handleClinicInput(idx, 'country_id', true)}
                                  isSearchable={true}
                                />
                                {profileValidator.message(`clinic-${idx}-country`, clinic.country_id, 'required')}
                              </div>
                            </div>
                          </div>
                        ))}
                        {data.clinics.length === 1 && (
                          <h5 className="float-left ml-1  mt-2 add-option" onClick={addClinic}>
                            + Add Secondary Clinic
                          </h5>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-row">
                        <FormInput
                          type="text"
                          className="col-12"
                          name="address_line_1"
                          label="Address"
                          value={data.userAddress.line1 || ''}
                          info="Street Address"
                          onChange={(e) =>
                            setData({ ...data, userAddress: { ...data.userAddress, line1: e.target.value } })
                          }
                          error={profileValidator.message('address', data.userAddress.line1, 'required')}
                        />
                      </div>
                      <div className="form-row">
                        <FormInput
                          type="text"
                          className="col-12"
                          name="address_line_1"
                          value={data.userAddress.line2 || ''}
                          info="Address Line 2"
                          onChange={(e) =>
                            setData({ ...data, userAddress: { ...data.userAddress, line2: e.target.value } })
                          }
                        />
                      </div>
                      <div className="form-row">
                        <FormInput
                          type="text"
                          className="col-sm-12 col-md-6"
                          name="city"
                          label="City"
                          value={data.user.city || ''}
                          onChange={(e) => setData({ ...data, user: { ...data.user, city: e.target.value } })}
                        />
                        <div className="col-sm-12 col-md-6">
                          <label className="label">State</label>
                          <Select
                            name="userState"
                            className={`${
                              profileValidator.message('userState', data.user.state_id, 'required')
                                ? 'rounded dropdown-border'
                                : ''
                            }`}
                            options={userStates}
                            value={userStates.find((x) => x.value === data.user.state_id) || ''}
                            onChange={(e) => setData({ ...data, user: { ...data.user, state_id: e.value } })}
                            isSearchable={true}
                          />
                          {profileValidator.message('userState', data.user.state_id, 'required')}
                        </div>
                      </div>
                      <div className="form-row">
                        <FormInput
                          type="text"
                          className="col-sm-12 col-md-6"
                          name="city"
                          label="Postcode"
                          value={data.user.zip_code || ''}
                          onChange={(e) => setData({ ...data, user: { ...data.user, zip_code: e.target.value } })}
                        />
                        <div className="col-sm-12 col-md-6">
                          <label className="label">Country</label>
                          <Select
                            name="userCountry"
                            className={`${
                              profileValidator.message('userCountry', data.user.country_id, 'required')
                                ? 'rounded dropdown-border'
                                : ''
                            }`}
                            options={data.countries}
                            value={data.countries.find((x) => x.value === data.user.country_id) || ''}
                            onChange={changeCountry}
                            isSearchable={true}
                          />
                          {profileValidator.message('userCountry', data.user.country_id, 'required')}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-5">
              <Button
                text="Update Profile"
                loading={processLoader}
                type="submit"
                className={`btn float-right mr-3 profile-button mb-5 ${
                  user.role_id === USER_ROLES.patient ? 'success' : 'btn-primary btn-md'
                }`}
                onClick={handleSubmit}
              />
              {user.role_id === USER_ROLES.patient && (
                <Button
                  text="Back"
                  className="btn backtohome btn-md float-right mr-3 profile-button mb-5"
                  onClick={() => navigate('/home')}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserProfile;
