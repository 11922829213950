import axios from 'axios';
import { useEffect, useState } from 'react';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGetUser');
const { url } = settings;

export interface UserDetails {
  firstName: string;
  gpCode?: string;
  mPatNumber?: string;
  roleId?: number;
}

export interface UserResponseData {
  first_name: string;
  gp_code?: string;
  patNumber?: string;
  role_id?: number;
}

const useGetUser = (): UserDetails => {
  const [userDetails, setUserDetails] = useState<UserDetails>({
    firstName: '',
    gpCode: '',
    mPatNumber: '',
    roleId: -1
  });

  useEffect(() => {
    axios
      .get<UserResponseData>(`${url}/user`)
      .then((response) => {
        const { data } = response;
        setUserDetails({
          firstName: data.first_name,
          gpCode: data.gp_code,
          mPatNumber: data.patNumber,
          roleId: data.role_id
        });
      })
      .catch((err) => {
        logger.error(err);
      });
  }, []);

  return userDetails;
};

export default useGetUser;
