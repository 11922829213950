import type { AxiosError, AxiosResponse } from 'axios';
import axios, { isAxiosError } from 'axios';
import { toast } from 'react-toastify';

import type {
  Country,
  PharmacyAllocationSettings,
  PharmacyInventoryHistory,
  PharmacyProductInventory,
  PharmacyUpdate,
  SearchPharmacy,
  State,
  TableSortOrder,
  UpdatePharmacy
} from '@/types';

import { Logger } from '@/utils/logger';

import settings from '../constants/constants';

import { AuthService } from './authentication.service';

const logger = new Logger('pharmacy.service');

axios.defaults.headers.common.Authorization = `Bearer ${AuthService.getUser() ? AuthService.getUser()?.token : null}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const API_URL = `${settings.url}/pharmacy`;

const API_URL_ADMIN = `${settings.adminApiUrl}/pharmacy`;

type PharmacyUploadError = AxiosError & {
  response: AxiosResponse & {
    data: {
      message: string;
    };
  };
};
const pharmacyUpload = async (formdata: FormData) => {
  if (!formdata) {
    logger.error('pharmacyUpload, incorrect data');
    toast.error('Incorrect data!');
    return;
  }

  try {
    await axios.post(`${API_URL}/upload`, formdata, { headers: { 'Content-Type': 'multipart/form-data' } });
  } catch (error) {
    if (isAxiosError(error)) {
      toast.error((error as PharmacyUploadError)?.response?.data?.message || 'Upload failed', {});
      logger.error('pharmacyUpload', error?.response?.data?.message);
    } else {
      toast.error('Upload failed', {});
      logger.error('pharmacyUpload failed with unknown reason');
    }
  }
};

const getUpdateHistory = async () => axios.get<PharmacyUpdate[]>(`${API_URL}/update-pharmacy-history`);

export type PharmacyInventoryHistoryResponse = {
  status: number;
  message: string;
  count: number;
  inventoryHistory: PharmacyInventoryHistory[];
};

export type PharmacyInventoryHistoryParams = {
  limit: number;
  offset: number;
  sort: string;
  sortOrder: 'DESC' | 'ASC';
  filter: string;
};
const getInventoryHistory = (pharmacyId: number, params?: PharmacyInventoryHistoryParams) =>
  axios.get<PharmacyInventoryHistoryResponse>(`${API_URL}/${pharmacyId}/inventory-history`, { params });

// TODO: FX-614 - Remove this when the feature is being enabled permanently.
const getPharmacyProducts = (pharmacyId: number) => axios.get(`${API_URL}/${pharmacyId}/products`);

const getPharmacyProductInventory = (pharmacyId: number) =>
  axios.get<{ data: PharmacyProductInventory[] }>(`${API_URL}/${pharmacyId}/product-inventory`);

// get entire country list around the world
const getPharmacyCountries = async () => (await axios.get<Country[]>(`${API_URL}/countries`)).data;

// state based on country
const getPharmacyStates = async (id: number) => (await axios.get<State[]>(`${API_URL}/states/${id}`)).data;

const getPharmacyData = (excelData: boolean) => axios.get(API_URL, { params: { excelData } });

const getPharmacyAllocationSettings = () => axios.get(`${API_URL}/dispense-stats`);

const setPharmacySameDayDelivery = (pharmacyId: number, sameDayDeliveryEnabled: boolean) =>
  axios.put(`${API_URL}/setSameDayDelivery/${pharmacyId}`, { sameDayDeliveryEnabled });

const updatePharmacyAllocationSettings = (data: PharmacyAllocationSettings[]) =>
  axios.put(`${API_URL_ADMIN}/allocation-settings`, data);

type GetPharmacySearchParams = {
  pageSize: number;
  page: number;
  sortingOrder: TableSortOrder;
  filter: string;
};

export type PharmacySearchResult = Pick<
  SearchPharmacy,
  'id' | 'pharmacy_code' | 'name' | 'state' | 'city' | 'zip_code' | 'address' | 'phone' | 'identifier_name'
> & {
  email: string;
};

export type PharmacySearchResponse = {
  pharmacies: PharmacySearchResult[];
  count: number;
};
const searchPharmacy = async ({ pageSize, page, sortingOrder, filter }: GetPharmacySearchParams) =>
  axios.get<PharmacySearchResponse>(`${API_URL}/search`, {
    params: {
      limit: pageSize,
      offset: page * pageSize,
      sort: sortingOrder.name,
      sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
      filter,
      ts: new Date().getTime()
    }
  });

interface UpdatePharmacyData {
  updateData: UpdatePharmacy[];

  fileName: string;
}

export const updatePharmacy = async (data: UpdatePharmacyData) => {
  const response = await axios.post(`${API_URL}/updatePharmacy`, data);
  return response.data;
};

// eslint-disable-next-line import/prefer-default-export
export const PharmacyService = {
  pharmacyUpload,
  getUpdateHistory,
  getInventoryHistory,
  getPharmacyCountries,
  getPharmacyData,
  getPharmacyAllocationSettings,
  getPharmacyProducts,
  getPharmacyProductInventory,
  getPharmacyStates,
  setPharmacySameDayDelivery,
  updatePharmacyAllocationSettings,
  searchPharmacy,
  updatePharmacy
};
