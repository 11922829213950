import { useOrders } from '@/context/orders/Orders';
import { OrderService } from '@/services/order.service';
import { Logger } from '@/utils/logger';
import { MenuItem } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const logger = new Logger('ExportAndShipMenuItem');

function ExportAndShipMenuItem() {
  const { selectedOrders, refreshOrders, clearSelectedOrders } = useOrders();

  const exportAndShipMutation = useMutation({
    mutationFn: async () => {
      try {
        const response = await OrderService.updateShippedStatus(selectedOrders);
        return response.data;
      } catch (error: any) {
        throw new Error(`Failed to update status to shipped: ${error.message}`);
      }
    },
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (error: any) => {
      logger.error(`ExportAndShipMutation: ${error.message}`);
      toast.error('No orders found eligible for shipment');
    }
  });

  const handleExportAndShip = async () => {
    await exportAndShipMutation.mutateAsync();
    clearSelectedOrders();
    refreshOrders();
  };
  return <MenuItem onClick={handleExportAndShip}>Export & Ship</MenuItem>;
}

export default ExportAndShipMenuItem;
