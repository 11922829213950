import { HttpStatus } from '@montugroup/apis-styleguide';
import { getShippingAddress } from '@montugroup/circuit-api-contracts';
import { useQuery } from '@tanstack/react-query';
import { TsRestResponseError } from '@ts-rest/core';

import { getTsCustomerPortalClient } from '@/api/customer-portal.client';

const client = getTsCustomerPortalClient();

export default function useGetShippingAddress(orderCode: string, enabled?: boolean) {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['shippingAddress', orderCode],
    queryFn: async () => {
      const res = await client.getShippingAddress.query({ params: { orderCode } });
      if (res.status !== HttpStatus.OK) {
        throw new TsRestResponseError(getShippingAddress, res);
      }
      return res.body.data;
    },
    retry: (_, error) => {
      if (error instanceof TsRestResponseError) {
        return false;
      }
      return true;
    },
    enabled
  });

  return {
    data,
    isError,
    isLoading
  };
}
