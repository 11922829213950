import React, { useEffect, useState, useRef } from 'react';
import FormInput from '../common/FormInput';
import Button from '../common/button';
import DatePicker from 'react-datepicker';
import { getApApprovalFormData } from '../../services/data.service';
import moment from 'moment';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import axios from 'axios';
import settings from '../../constants/constants';
import { useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Logger } from '@/utils/logger';

const logger = new Logger('apApprovalForm');

const ApApprovalForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const url = settings.url;
  const [data, setData] = useState({
    gpUser: {},
    ingredients: [],
    formulation: [],
  });
  const [apFile, setApFile] = useState('');
  const [apFileError, setApFileError] = useState('');
  const [apMaP, setApMap] = useState('');
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setEndDate] = useState(moment().add(24, 'months').toDate());
  const [defaultDate] = useState(moment().subtract(2, 'year').toDate());
  const [selectedIngredient, setSelectedIngredient] = useState([]);
  const [selectedFormulation, setSelectedFormulation] = useState([]);

  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();

  const getSpecificGpData = async () => {
    const formData = await getApApprovalFormData(id);
    setData({
      gpUser: formData.data.user,
      ingredients: formData.data.ingredients.map((x) => ({ label: x.name, value: x.id })),
      formulation: formData.data.formulation.map((x) => ({ label: x.name, value: x.id })),
    });
  };

  useEffect(() => {
    getSpecificGpData();
  }, []);

  const handleFile = (e) => {
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg', 'pdf'].indexOf(fileExtension) === -1) {
      setApFileError('This File type is not allowed');
    } else if (fileSize > 26214400) {
      setApFileError('AP File Exceeds Limit');
    } else {
      setApFile(file);
      setApFileError('');
    }
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleChange = (evt) => {
    setApMap(evt.target.value);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }
    setLoading(true);
    if (formValid) {
      let formData = new FormData();
      formData.append('file', apFile);
      formData.append('gpUser_id', id);
      formData.append('ingredientId', selectedIngredient.value);
      formData.append('formulationId', selectedFormulation.value);
      formData.append('tz', moment().format('Z'));
      formData.append('startDate', moment(startDate).utc());
      formData.append('endDate', moment(endDate).utc());
      formData.append('ap_map_number', apMaP);
      try {
        const response = await axios.post(url + '/ap/', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        if (response.status === 200) {
          toast.success('Approval submitted successfully');
          setLoading(false);
          navigate('/orders');
        } else {
          throw new Error('Unexpected response status');
        }
      } catch (error) {
        setLoading(false);
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error('An error occurred while submitting the approval');
        }
        logger.error('Approval submission error:', error);
      }
    }
  };

  return (
    <div className="container sas-form">
      <form>
        <span className="h3">Upload AP Documentation</span>
        <div className="col-md-8 mt-4">
          <div className="form-group">
            <div className="form-group">
              <label>Doctor Name</label>
              <FormInput
                type="text"
                name="doctor_name"
                readOnly
                value={data.gpUser.first_name + ' ' + data.gpUser.last_name}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-sm-6">
              <div className="form-group p-1">
                <label>Attach AP Approval Form</label>
                <small className="text-secondary d-block">(jpeg,png,jpg,pdf) are allowed</small>
                <div className="file-upload mt-2 p-2">
                  <label className="d-block" htmlFor="file-input">
                    <i className="fa fa-cloud-upload fa-5x" aria-hidden="true"></i>
                    <input id="file-input" name="apFile" className="d-none" type="file" onChange={handleFile} />
                    {(() => {
                      if (apFile === '' && apFileError === '') {
                        return (
                          <React.Fragment>
                            <p className="mb-0 text-primary">Choose a file</p>
                            <small>File limit 25MB</small>
                          </React.Fragment>
                        );
                      } else if (apFileError !== '') {
                        return <p className="text-danger p-1">{apFileError}</p>;
                      } else {
                        return <p className="file-name">{apFile.name}</p>;
                      }
                    })()}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="form-row">
            <FormInput
              type="text"
              name="apmap"
              label="AP MAP Number"
              className="col-sm-4"
              value={apMaP}
              onChange={handleChange}
              error={validator.current.message('map number', apMaP, 'required')}
            />
          </div>

          <div className="form-row">
            <div className="form-group p-1 col-sm-4">
              <label className="d-block"> AP Start Date </label>
              <DatePicker
                className="form-control"
                id="dataPicker"
                selected={startDate}
                onChange={handleStartDate}
                minDate={defaultDate}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
              />
              <label htmlFor={'dataPicker'}>
                <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
              </label>
            </div>
            <div className="form-group p-1 col-sm-4">
              <label className="d-block"> AP End Date </label>
              <DatePicker
                className="form-control"
                id="dataPickertwo"
                selected={endDate}
                onChange={handleEndDate}
                minDate={defaultDate}
                showMonthDropdown
                showYearDropdown
                dateFormat="dd/MM/yyyy"
                dropdownMode="select"
              />
              <label htmlFor={'dataPickertwo'}>
                <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group p-1 mb-0 pb-0 col-12">
              <label>Approved Category</label>
              <Select
                name="Ingredient"
                isSearchable={true}
                options={data.ingredients}
                onChange={(e) => setSelectedIngredient(e)}
                value={selectedIngredient}
                className={`${
                  validator.current.message('Category', selectedIngredient, 'required') ? 'rounded dropdown-border' : ''
                }`}
              />
              {validator.current.message('Category', selectedIngredient, 'required')}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group p-1 mb-0 pb-0 col-12">
              <label>Approved Formulation</label>
              <Select
                name="Ingredient"
                isSearchable={true}
                options={data.formulation}
                onChange={(e) => setSelectedFormulation(e)}
                value={selectedFormulation}
                className={`${
                  validator.current.message('Formulation', selectedFormulation, 'required')
                    ? 'rounded dropdown-border'
                    : ''
                }`}
              />
              {validator.current.message('Formulation', selectedFormulation, 'required')}
            </div>
          </div>

          <Button
            text="Submit"
            loading={loading}
            type="submit"
            className="btn btn-primary btn-md ml-1 mb-5 float-right mt-5"
            onClick={handleSubmission}
          />
        </div>
      </form>
    </div>
  );
};

export default ApApprovalForm;
