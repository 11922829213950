import { Logger } from '@/utils/logger';
import { QueryCache, QueryClient } from '@tanstack/react-query';

const logger = new Logger('QueryClient');
let queryClient: QueryClient | undefined;

const getQueryClient = () => {
  if (!queryClient) {
    queryClient = new QueryClient({
      queryCache: new QueryCache({
        // For context see https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose#defining-on-demand-messages
        onError: (error, query) => {
          if (typeof query.meta?.onError === 'function') {
            try {
              query.meta.onError(error);
            } catch (localError) {
              logger.error('onError handler unexpected error', { error, localError });
            }
          } else {
            logger.warn('ignored error', error);
          }
        }
      })
    });
  }

  return queryClient;
};

export default getQueryClient;
