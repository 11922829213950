import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { LinkProps } from '@mui/material';
import {
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Accordion as MUIAccordion,
  Stack,
  styled,
  Typography
} from '@mui/material';
import type { SyntheticEvent } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import type { Prescription } from './common';
import { getCreatedDate, getDaysUntilExpiry, getExpiryDate, getInterval, getProductName, getQuantity } from './common';
import PrescriptionDosageChip from './PrescriptionDosageChip';
import PrescriptionStatusChip, { StatusChip } from './PrescriptionStatusChip';

export interface IPrescriptionAccordion {
  prescription: Prescription;
  expanded: boolean | number;
  onChange: (productId: number) => (event: SyntheticEvent, isExpanded: boolean) => void;
}

interface StyledLinkProps extends LinkProps {
  component: typeof ReactRouterLink;
  to: string;
}

const Accordion = styled(MUIAccordion)(() => ({
  border: 'none',
  boxShadow: 'none',
  '&::before': {
    display: 'none'
  },
  '& + &::before': {
    display: 'block'
  },

  '&.MuiAccordion-root.Mui-expanded::before': {
    opacity: 1
  }
}));

const AccordionHeader = styled(AccordionSummary)(({ theme }) => ({
  paddingLeft: theme.spacing(0),
  paddingRight: theme.spacing(0),
  '.MuiAccordionSummary-expandIconWrapper': {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-start'
  }
}));

const AccordionRow = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 4),
  flexDirection: 'row'
}));

const AccordionStrengthRow = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
  flexDirection: 'row'
}));

const DosageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '3px'
}));

const StyledLink = styled(Link)<StyledLinkProps>(({ theme }) => ({
  color: theme.palette.info.main,
  textDecorationColor: theme.palette.info.main,
  fontFamily: theme.typography.body1.fontFamily
}));

export function PrescriptionAccordion({ prescription, expanded, onChange }: IPrescriptionAccordion) {
  const daysUntilExpiry = getDaysUntilExpiry(prescription);
  return (
    <Accordion
      disableGutters
      expanded={expanded === prescription.productId}
      onChange={onChange(prescription.productId)}
      data-testid="prescription-accordion"
    >
      <AccordionHeader expandIcon={<ExpandMoreIcon />} aria-controls="prescription-content" id="prescription-content">
        <Stack sx={{ width: '100%' }}>
          <Typography fontWeight="bold" sx={{ marginBottom: 3 }}>
            {getProductName(prescription)}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 3 }}>
            <Typography>Created </Typography>
            <Typography fontWeight={(theme) => theme.typography.fontWeightMedium} sx={{ marginRight: '-1.2rem' }}>
              {getCreatedDate(prescription)}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography>Expiry </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {daysUntilExpiry <= 30 && daysUntilExpiry >= 1 ? (
                <StatusChip label={`${daysUntilExpiry} days left`} variant="filled" color="warning" size="small" />
              ) : null}
              <Typography
                fontWeight={(theme) => theme.typography.fontWeightMedium}
                sx={{ marginLeft: 2, marginRight: '-1.2rem' }}
              >
                {getExpiryDate(prescription)}
              </Typography>
            </Box>
          </Box>
          {prescription.inActive || prescription.status.toLowerCase() !== 'active' ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 3 }}>
              <Typography>Status </Typography>
              <Box sx={{ marginRight: '-1.2rem' }}>
                <PrescriptionStatusChip prescription={prescription} size="small" />
              </Box>
            </Box>
          ) : null}
        </Stack>
      </AccordionHeader>
      <AccordionDetails
        sx={{
          paddingX: 0
        }}
      >
        {(prescription.thcStrength || prescription.cbdStrength) && (
          <AccordionStrengthRow gap="0.5rem">
            {prescription.thcStrength && <PrescriptionDosageChip dosage="THC" strength={prescription.thcStrength} />}
            {prescription.cbdStrength && <PrescriptionDosageChip dosage="CBD" strength={prescription.cbdStrength} />}
          </AccordionStrengthRow>
        )}
        <DosageContainer>
          <Typography fontWeight={600}>Dosage </Typography>
          <Typography
            paddingTop={(theme) => theme.spacing(2)}
            fontSize={(theme) => theme.typography.fontSize}
            lineHeight="1.5rem"
          >
            {prescription?.dosage || '-'}
          </Typography>
        </DosageContainer>
        <AccordionRow justifyContent="space-between">
          <Typography>Max quantity per repeat</Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>{getQuantity(prescription)}</Typography>
        </AccordionRow>
        <Divider />
        <AccordionRow justifyContent="space-between">
          <Typography>Interval time</Typography>
          <Typography fontWeight={(theme) => theme.typography.fontWeightMedium}>{getInterval(prescription)}</Typography>
        </AccordionRow>
        {prescription.productActive && (
          <>
            <Divider />
            <AccordionRow justifyContent="space-between">
              <Typography>View product</Typography>
              <StyledLink component={ReactRouterLink} to={`/product-detail/${prescription.productId}`}>
                View
              </StyledLink>
            </AccordionRow>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default PrescriptionAccordion;
