import moment from 'moment';

import { Logger } from '@/utils/logger';
import settings from '../constants/constants';

const logger = new Logger('patientCredit.service');

const calculateNetCreditDiscount = (orderProducts, currentDiscounts, credit, shipping, shippingGst) => {
  const totalProductAmount = orderProducts.reduce((a, v) => {
    const value = a + parseFloat(v.data.price) * parseFloat(v.data.quantity);
    return value;
  }, 0);
  let totalCredit = 0;
  if (credit && parseFloat(totalProductAmount) > 0) {
    totalCredit = parseFloat(credit.result.amount);
  }

  const totalDiscount = currentDiscounts
    .filter((item) => item.isDiscount === true)
    .reduce((a, v) => {
      const value = a + parseFloat(v.amount);
      return value;
    }, 0);

  const dispensingFee = shipping;
  const dispensingFeeTax = shippingGst.toFixed(2);
  const netTotal =
    parseFloat(totalProductAmount) +
    parseFloat(dispensingFee) -
    parseFloat(totalDiscount) +
    parseFloat(dispensingFeeTax);

  if (netTotal <= totalCredit) {
    totalCredit = netTotal;
  }

  return parseFloat(totalCredit).toFixed(2);
};

const getCreditName = (creditType) => {
  // Referall
  if (creditType.id === +settings.creditType.Referral) {
    return 'Referral (Non-Refundable)';
  }

  // Promotion
  if (creditType.id === +settings.creditType.Promotion) {
    return 'Promotion (Non-Refundable)';
  }

  return creditType.CreditCategory?.category_name ? creditType.CreditCategory?.category_name : 'Credit';
};

const groupCreditLineItem = (credits) => {
  const creditLineItems = [];

  if (!credits?.length) {
    return creditLineItems;
  }
  const result = {};

  credits.forEach((credit) => {
    if (!result[credit.name]) {
      result[credit.name] = {
        name: credit.name,
        amount: 0
      };
    }
    result[credit.name].amount += parseFloat(credit.amount);
    result[credit.name].id = credit.id;
    result[credit.name].quantity = 1;
    result[credit.name].isDiscount = false;
    result[credit.name].isProduct = false;
  });

  creditLineItems.push(...Object.values(result));
  return creditLineItems;
};

// TODO: Don't re-implement this business logic on FE
function isPatientDiscountActive(patientDiscount) {
  if (!patientDiscount) {
    return false;
  }

  const isWithinOrderLimit = patientDiscount.order_limit !== 0 || patientDiscount.order_limit === null;
  if (!isWithinOrderLimit) {
    return false;
  }
  if (patientDiscount.is_indefinite) {
    return true;
  }
  const now = moment();
  return moment(patientDiscount.start_date).isBefore(now) && moment(patientDiscount.end_date).isAfter(now);
}

function isPatientDiscountInOrderDiscounts(patientDiscountId, orderDiscounts) {
  if (!patientDiscountId || !orderDiscounts) {
    return false;
  }

  return !!orderDiscounts.find((orderDiscount) => orderDiscount?.PatientDiscount?.id == patientDiscountId);
}

const getPatientDiscountCreditLineItems = (
  orderData,
  refundNewLogic,
  shipping,
  shippingGst,
  discountsAndCredits = []
) => {
  let i = 0;
  const consolidateLineItems = [];
  const fullOrderDiscount = orderData.patientDiscounts.find(
    (x) => x.discount_type_id === settings.discountType.FULL_ORDER
  );
  const isOrderDiscountActive =
    isPatientDiscountActive(fullOrderDiscount) ||
    isPatientDiscountInOrderDiscounts(fullOrderDiscount?.id, orderData.orderDiscounts);

  if (isOrderDiscountActive && fullOrderDiscount.discount_percentage) {
    const productsEligibleForFullOrderDiscount = orderData.selectedProducts.filter((item) => !item.data.is_concession);
    let productPrice = productsEligibleForFullOrderDiscount
      .map((item) => item.data.quantity * item.data.price)
      .reduce((prev, curr) => prev + curr, 0);
    productPrice = (parseFloat(productPrice) + shipping + shippingGst).toFixed(2);
    const discountPrice = (parseFloat(productPrice) * parseFloat(fullOrderDiscount.discount_percentage)) / 100;

    discountsAndCredits.push({
      id: `discount-${(i += 1)}`,
      name: `Discount ${Math.floor(fullOrderDiscount.discount_percentage)} %`,
      amount: parseFloat(discountPrice).toFixed(2),
      isProduct: false,
      isDiscount: true
    });
  }

  if (!isOrderDiscountActive) {
    orderData.selectedProducts.forEach((product) => {
      const isConcessionProduct = product.data.is_concession;

      // TODO: Don't re-implement this business logic on FE
      const patientDiscounts = orderData.patientDiscounts;
      const applicableDiscounts = patientDiscounts
        .filter((patientDiscount) => {
          if (isConcessionProduct) {
            return false; // Concession products have no further discounts
          }
          return patientDiscount.is_concession || `${patientDiscount.product_id}` === `${product.data.product_id}`;
        })
        .filter((patientDiscount) => {
          return (
            isPatientDiscountActive(patientDiscount) ||
            isPatientDiscountInOrderDiscounts(patientDiscount?.id, orderData.orderDiscounts)
          );
        })
        .sort((a, b) => {
          // Existing FE doesn't support discount_amount discounts
          const aValue = a.discount_percentage || 0;
          const bValue = b.discount_percentage || 0;
          return bValue - aValue; // Ascending for higher % first
        });
      const currentDiscount = applicableDiscounts[0];
      logger.debug('Evaluated product specific discount', { patientDiscounts, product, applicableDiscounts });
      let discountTotal = 0;

      if (currentDiscount && currentDiscount.discount_percentage) {
        discountTotal = (product.data.price * product.data.quantity * currentDiscount.discount_percentage) / 100;

        discountsAndCredits.push({
          amount: discountTotal,
          isDiscount: true,
          isProduct: true,
          name: `${currentDiscount.is_concession ? 'Concession' : ''} Discount ${Math.floor(
            currentDiscount.discount_percentage
          )} % - ${product.data.short_name || product.data.product_name} `.trim(),
          quantity: '1',
          id: `discount-${(i += 1)}`
        });
      }
    });

    // checking if Shipping Fee Discount is available and active (within start_date and end_date || is_indefinite)
    // priority for Full Order Discount
    const shippingDiscount = orderData.patientDiscounts.find(
      (x) => x.discount_type_id === settings.discountType.DISPENSING_FEE
    );
    const isDiscountActive = isPatientDiscountActive(shippingDiscount);
    const isDiscountAlreadyAppliedToOrder = isPatientDiscountInOrderDiscounts(
      shippingDiscount?.id,
      orderData.orderDiscounts
    );
    const shouldShowShippingDiscount = isDiscountAlreadyAppliedToOrder || isDiscountActive;
    let totalDisFee = 0;

    if (shouldShowShippingDiscount && shippingDiscount.discount_percentage) {
      totalDisFee = (((shipping + shippingGst) * parseFloat(shippingDiscount.discount_percentage)) / 100).toFixed(2);

      discountsAndCredits.push({
        amount: parseFloat(totalDisFee).toFixed(2),
        isDiscount: true,
        isProduct: true,
        name: `Discount ${Math.floor(shippingDiscount.discount_percentage)} % - Shipping`,
        quantity: '1',
        id: `discount-${(i += 1)}`,
        discountType: shippingDiscount.discount_type_id,
        discountPercentage: shippingDiscount.discount_percentage
      });
    }
  }

  const patientCredits = orderData.patientCredit;

  if (!refundNewLogic) {
    if (patientCredits) {
      const creditAmount = calculateNetCreditDiscount(
        orderData.selectedProducts,
        discountsAndCredits,
        patientCredits,
        shipping,
        shippingGst
      );

      discountsAndCredits.push({
        id: `credit-${(i += 1)}`,
        name: 'Credit',
        quantity: '1',
        amount: creditAmount,
        isProduct: false,
        isDiscount: false
      });
    }

    return discountsAndCredits;
  }

  if (refundNewLogic) {
    if (!patientCredits?.length) {
      return discountsAndCredits;
    }

    const totalProductAmount = orderData.selectedProducts.reduce((a, v) => {
      const value = a + parseFloat(v.data.price) * parseFloat(v.data.quantity);
      return value;
    }, 0);
    const totalDiscount = discountsAndCredits
      ?.filter((item) => item.isDiscount === true)
      ?.reduce((a, v) => {
        const value = a + parseFloat(v.amount);
        return value;
      }, 0);

    const dispensingFee = shipping;
    const dispensingFeeTax = shippingGst.toFixed(2);
    let netTotal =
      parseFloat(totalProductAmount) +
      parseFloat(dispensingFee) -
      parseFloat(totalDiscount) +
      parseFloat(dispensingFeeTax);

    // let creditLineItemAmount;
    for (let patientCredit = 0; patientCredit < patientCredits?.length; patientCredit += 1) {
      const credit = patientCredits[patientCredit];
      if (orderData?.selectedProducts?.length > 0) {
        if (parseFloat(credit?.amount) > parseFloat(netTotal)) {
          // credit = 600 and netTotal = 400
          discountsAndCredits.push({
            id: `credit-${(i += 1)}`,
            name: getCreditName(credit?.CreditType),
            quantity: '1',
            amount: parseFloat(netTotal),
            isProduct: false,
            isDiscount: false
          });

          break;
        }

        if (parseFloat(credit) === parseFloat(netTotal)) {
          // credit = 400 and netTotal = 400
          discountsAndCredits.push({
            id: `credit-${(i += 1)}`,
            name: getCreditName(credit?.CreditType),
            quantity: '1',
            amount: parseFloat(netTotal),
            isProduct: false,
            isDiscount: false
          });

          break;
        }

        if (parseFloat(credit?.amount) < parseFloat(netTotal)) {
          // credit = 100 and netTotal = 400
          discountsAndCredits.push({
            id: `credit-${(i += 1)}`,
            name: getCreditName(credit?.CreditType),
            quantity: '1',
            amount: parseFloat(credit.amount),
            isProduct: false,
            isDiscount: false
          });

          netTotal -= parseFloat(credit.amount);
        }
      }
    }

    const filterOutCreditLineItems = discountsAndCredits.filter((x) => !x.isDiscount);

    if (!filterOutCreditLineItems.length) {
      return discountsAndCredits;
    }

    const filterDiscountLineItems = discountsAndCredits.filter((x) => x.isDiscount);

    if (filterDiscountLineItems?.length) {
      // push discount line item in consolidatelineItems array
      consolidateLineItems.push(...filterDiscountLineItems);
    }

    const creditLineItems = groupCreditLineItem(filterOutCreditLineItems);

    if (creditLineItems?.length) {
      // push credit line item in consolidatelineItems array
      consolidateLineItems.push(...creditLineItems);
    }

    return consolidateLineItems;
  }
  return [];
};

export { calculateNetCreditDiscount, getPatientDiscountCreditLineItems, groupCreditLineItem };
