import React, { useEffect, useState } from 'react';
import productViewModel from './productViewModel';
import constantsViewModel from './constantsViewModel';
import FormInput from '../../../components/common/FormInput';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import Select from 'react-select';
import Button from '../../../components/common/button';
import {
  createProduct,
  getAlternativeProducts,
  getAvailableProductsList,
  getPrescriptionRange,
  getProductEdit,
  postPrescriptionRange,
  setAlternativeProductsForId,
} from '@/services/product.service';
import { toast } from 'react-toastify';
import moment from 'moment';
import CircleLoader from '../../common/circleLoader';
import { toastOptions } from '@/components/common/toastConfirm';
import CustomModal from '../../common/CustomModal';
import AlternativeProductSelect from '@/components/products/components/AlternativeProducts/AlternativeProductSelect';
import { Logger } from '@/utils/logger';
import useSplitProductsModal, { splitModalStyles } from '@/hooks/products/useSplitProductsModal';
import useGenerativeScriptingSafeguards, { gScriptWarningModalStyles } from '@/hooks/useGenerativeScriptingSafeguards';
import { Alert } from '@mui/material';
import getProductImageUrl from '@/utils/getProductImageUrl';
import { useNavigate, useParams } from 'react-router-dom';

const logger = new Logger('productEdit');

const SHOPIFY_DISABLE_PRODUCT_EDIT = true;

const ProductEdit = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    showWarningModal,
    gScriptWarningType,
    warningElement,
    gScriptMetadata,
    setShowWarningModal,
    tryChangeGScriptingSetting,
  } = useGenerativeScriptingSafeguards();

  const [loading, setLoading] = useState(true);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const { showSplitModal, splitOOS, splitOrders, splitModal } = useSplitProductsModal();
  const [newRange, setRange] = useState(null);
  const [constants, setConstants] = useState({
    suppliers: [],
    ingredients: [],
    schedules: [],
    formulations: [],
    thcs: [],
    cbds: [],
    hybrids: [],
    spectrums: [],
    strains: [],
    rxRange: [],
  });
  const productId = params.id === 'new' ? null : params.id;
  const [data, setData] = useState({
    name: '',
    price: '',
    description: '',
    sku: '',
    supplier: '',
    approved: false,
    available2all: false,
    new_product: false,
    coming_soon: false,
    new_at_lowprice: false,
    campaign_discount_price: false,
    active: '1',
    ingredient: '',
    formulation: '',
    range: '',
    cultivar: '',
    strain: '',
    thc_percentage: '',
    thc_strength: '',
    cbd_strength: '',
    schedule: '',
    thc: '',
    cbd: '',
    hybrid: '',
    spectrum: '',
    hadSupplier: false,
    interval: '',
    dosage: '',
    outOfStock: false,
    stockReturnDate: null,
    reasoningToggle: false,
    reasoning: '',
    weight: '',
    existingsku_codes: [],
    existingsku_code: '',
    order: null,
    short_name: '',
    size: '',
    how_to_use: '',
    contents: '',
    conditions: '',
    thc_strength_for_display: '',
    cbd_strength_for_display: '',
    description_for_display: '',
    cbd_thc_ratio: '',
    patient_catalog: false,
    product_image: '',
    ProductImages: [],
    handleInputProp: '',
    intervalLimit: '',
    isSupplyChainRisk: false,
    isGenerativeScripting: false,
    isExcludedFromNewPrescriptions: false,
  });

  const [alternativeAvailableProducts, setAlternativeAvailableProducts] = useState([]);
  const [alternativeDefaultProducts, setAlternativeDefaultProducts] = useState([]);
  const [availableProductsMap, setAvailableProductsMap] = useState(new Map());

  const validator = new SimpleReactValidator({
    validators: {
      intervalLimit: {
        message: 'Interval range between 1 to 14',
        rule: (val) => {
          return data.interval === val && val > 1 && val <= 14;
        },
        required: true,
      },
    },
  });

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [fileError, setFileError] = useState('');
  const [errors, setErrors] = useState({
    sku: validator.message('sku', data.sku, 'required'),
    name: validator.message('name', data.name, 'required'),
    price: validator.message('price', data.price, 'required'),
    formulation: validator.message('formulation', data.formulation.value, 'required'),
    ingredient: validator.message('category', data.ingredient && data.ingredient.value, 'required'),
    interval: validator.message('interval', data.interval, 'required'),
    schedule: validator.message('schedule', data.schedule && data.schedule.value, 'required'),
    ...((data.intervalLimit < 1 || data.intervalLimit > 14) && {
      intervalLimit: validator.message('intervalLimit', data.intervalLimit, 'intervalLimit'),
    }),
  });

  const handleUserInput =
    (prop, type = 'text') =>
    (e) => {
      let value = type === 'boolean' ? e.target.checked : e.target.value;
      if (type === 'number') {
        value = parseInt(value);
      } else if (type === 'date') {
        value = e;
      }

      const newData = {
        ...data,
        [prop]: value,
        handleInputProp: prop,
      };

      if (prop === 'new_at_lowprice' && value) {
        newData.new_product = false;
        newData.coming_soon = false;
        newData.campaign_discount_price = false;
      } else if (prop === 'new_product' && value) {
        newData.new_at_lowprice = false;
        newData.coming_soon = false;
        newData.campaign_discount_price = false;
      } else if (prop === 'coming_soon' && value) {
        newData.new_at_lowprice = false;
        newData.new_product = false;
        newData.campaign_discount_price = false;
      } else if (prop === 'campaign_discount_price' && value) {
        newData.new_at_lowprice = false;
        newData.new_product = false;
        newData.coming_soon = false;
      }

      setData(newData);
    };

  const handleGScriptSettingChange = () => {
    setShowWarningModal(false);
    switch (gScriptWarningType) {
      case 'mark-as-oos':
      case 'gscript-to-oos':
        if (gScriptMetadata.prop === 'outOfStock') {
          setData({
            ...data,
            reasoningToggle: false,
            reasoning: '',
            isGenerativeScripting: false,
            outOfStock: gScriptMetadata.updatedValue,
            handleInputProp: gScriptMetadata.prop,
          });
        } else if (gScriptMetadata.prop === 'reasoningToggle') {
          setData({
            ...data,
            outOfStock: false,
            isGenerativeScripting: false,
            reasoningToggle: gScriptMetadata.updatedValue,
            handleInputProp: gScriptMetadata.prop,
          });
        }
        break;
      case 'mark-as-gscript':
      case 'oos-to-gscript':
        setData({
          ...data,
          reasoningToggle: false,
          reasoning: '',
          outOfStock: false,
          isGenerativeScripting: gScriptMetadata.updatedValue,
          handleUserInput: gScriptMetadata.prop,
        });
        break;
      case 'turn-off-gscript':
        setData({
          ...data,
          isGenerativeScripting: false,
          handleUserInput: 'isGenerativeScripting',
        });
        break;
      case 'turn-off-oos':
        setData({
          ...data,
          stockReturnDate: null,
          reasoningToggle: false,
          reasoning: '',
          outOfStock: false,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      data.handleInputProp === 'outOfStock' ||
      data.handleInputProp === 'reasoningToggle' ||
      data.handleInputProp === 'stockReturnDate'
    ) {
      splitModal({
        productId,
        returnDate: data.stockReturnDate,
        reasoningToggle: data.reasoningToggle,
        outOfStock: data.outOfStock,
      });
      setData({ ...data, handleInputProp: '' });
    }
  }, [data.handleInputProp]);

  useEffect(() => {
    return () => {
      toast.dismiss(toastOptions.toastId);
    };
  }, []);

  const handleIngredient = (e) => {
    setData({
      ...data,
      ingredient: e,
      approved: e.value === 'approved',
    });
  };

  const handleFile = (e) => {
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg'].indexOf(fileExtension) === -1) {
      setFileError('This File type is not allowed');
    } else if (fileSize > 5242880) {
      setFileError('Image Size is Large');
    } else {
      setFile(file);
      setFileName(fileName);
      setFileExtension(fileExtension);
      setFileError('');
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    if (data.outOfStock && !data.stockReturnDate) {
      toast.error('Please select a stock return date');
      return;
    }
    if (data.reasoningToggle && !data.reasoning) {
      toast.error('Please enter reasoning');
      return;
    }

    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        sku: validator.message('sku', data.sku, 'required'),
        name: validator.message('name', data.name, 'required'),
        price: validator.message('price', data.price, 'required'),
        formulation: validator.message('formulation', data.formulation.value, 'required'),
        ingredient: validator.message('category', data.ingredient && data.ingredient.value, 'required'),
        interval: validator.message('interval', data.interval, 'required'),
        schedule: validator.message('schedule', data.schedule && data.schedule.value, 'required'),
        ...((data.intervalLimit > 14 || data.intervalLimit < 1) && {
          intervalLimit: validator.message('intervalLimit', data.intervalLimit, 'intervalLimit'),
        }),
      });
      toast.warn('Please fill all the mandatory fields');
      return;
    }

    if (data.sku && data.existingsku_codes) {
      if (data.existingsku_codes.indexOf(data.sku) > -1) {
        toast.error('SKU code already exists');
        return;
      }
    }
    setSubmitInProgress(true);
    let formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('fileExtension', fileExtension);

    const sendBody = {
      product: {
        name: data.name,
        price: data.price,
        description: data.description,
        sku_code: data.sku,
        pre_approved: data.approved,
        active: data.active,
        id: productId,
        available_to_all_gp: data.available2all,
        new_product: data.new_product,
        coming_soon: data.coming_soon,
        new_at_lowprice: data.new_at_lowprice,
        campaign_discount_price: data.campaign_discount_price,
        ingredient_id: data.ingredient.value === 'approved' ? null : data.ingredient.value,
        formulation_id: data.formulation.value,
        cultivar: data.cultivar,
        strain_id: data.strain ? data.strain.value : null,
        ...(data.thc_percentage && { thc_percentage: data.thc_percentage }),
        thc_strength: data.thc_strength,
        cbd_strength: data.cbd_strength,
        schedule_id: data.schedule ? data.schedule.value : null,
        thc_id: data.thc ? data.thc.value : null,
        cbd_id: data.cbd ? data.cbd.value : null,
        hybrid_id: data.hybrid ? data.hybrid.value : null,
        spectrum_id: data.spectrum ? data.spectrum.value : null,
        rx_range_id: data.range ? data.range.value : null,
        interval: data.interval,
        dosage: data.dosage,
        is_out_of_stock: data.outOfStock,
        stock_return_date: data.stockReturnDate ? moment(data.stockReturnDate).format('DD-MMM-YYYY') : null,
        reasoning_toggle: data.reasoningToggle,
        reasoning: data.reasoning,
        ...(data.weight && { weight: data.weight }),
        order: data.order,
        size: data.size,
        how_to_use: data.how_to_use,
        contents: data.contents,
        conditions: data.conditions,
        short_name: data.short_name,
        thc_strength_for_display: data.thc_strength_for_display,
        cbd_strength_for_display: data.cbd_strength_for_display,
        description_for_display: data.description_for_display,
        patient_catalog: data.patient_catalog,
        cbd_thc_ratio: data.cbd_thc_ratio,
        image_url: data.image_url,
        is_supply_chain_risk: data.isSupplyChainRisk,
        is_excluded_from_new_prescriptions: data.isExcludedFromNewPrescriptions,
        is_generative_scripting: data.isGenerativeScripting,
      },
      supplier: data.supplier.value,
      previousValues: {
        available_to_all_gp: data.wasAvailable2doc,
        pre_approved: data.wasApproved,
        supplier: data.hadSupplier,
        existingsku_code: data.existingsku_code,
        reasoning_toggle: data.wasReasoningToggle,
        is_out_of_stock: data.wasOutOfStock,
      },
      alternativeProducts: [],
      splitOOS,
      splitOrders,
    };

    let selectedDefaultProducts = [];
    if (data.reasoningToggle || data.isGenerativeScripting) {
      selectedDefaultProducts = alternativeDefaultProducts.filter((p) => p != null);
    }

    // need to add sku_code on the alternativeProducts object for each selected product
    sendBody.alternativeProducts = selectedDefaultProducts.map(({ value }) => {
      return { id: value, sku: availableProductsMap.get(value) };
    });

    formData.append('sendBody', JSON.stringify(sendBody));

    try {
      const res = await createProduct(formData);
      if (res.data.status === 400) {
        toast.error('Error while updating Product');
        logger.error('Error while updating Product');
        return;
      }

      await setAlternativeProductsForId(
        res.data.id,
        selectedDefaultProducts.map(({ value }) => value),
      );

      toast.success(`Product ${productId ? 'Updated' : 'Created'}`);
      navigate({ pathname: '/products' });
    } catch (err) {
      logger.error(`Product ${productId ? 'Update' : 'Create'} Failed. Please Try again later.`, err);
      toast.error(`Product ${productId ? 'Update' : 'Create'} Failed. Please Try again later.`);
    } finally {
      setSubmitInProgress(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      let productResponse;
      let availableProductsResponse;
      let alternativeProductsResponse;

      try {
        [productResponse, availableProductsResponse, alternativeProductsResponse] = await Promise.all([
          getProductEdit(productId),
          getAvailableProductsList(),
          productId ? getAlternativeProducts(productId) : null,
        ]);
        setLoading(false);
      } catch (e) {
        toast.error('Could not load product data. Please reload the page.', { autoClose: false });
        logger.error('Could not load product data', { error: e });
        return;
      }

      setData({
        ...data,
        ...productViewModel(productResponse.data),
      });

      setConstants(constantsViewModel(productResponse.data));

      const tempMap = new Map(availableProductsMap);
      const products = availableProductsResponse.data.filter((product) => {
        tempMap.set(product.id, product.sku_code);
        return product.id !== productId;
      });
      setAvailableProductsMap(tempMap);
      setAlternativeAvailableProducts(products);

      if (alternativeProductsResponse?.status === 200) {
        const selectedProducts = alternativeProductsResponse.data.map((product) => ({
          value: product.alternative_product_id,
          label: product.AlternativeProduct?.name || '',
        }));
        setAlternativeDefaultProducts(selectedProducts);
      }
    };
    loadData();
  }, [productId]);

  const fetchRange = async () => {
    const resp = await getPrescriptionRange();
    setConstants({
      ...constants,
      rxRange: resp.data.map((x) => ({ label: x.name, value: x.id })),
    });
  };

  const createRange = async (e) => {
    e.preventDefault();
    await postPrescriptionRange({ name: newRange });
    setRange(null);
    fetchRange();
  };

  if (loading) {
    return <CircleLoader />;
  }

  return (
    <div className="col-10 mx-auto">
      <CustomModal isOpen={showWarningModal} shouldCloseOnOverlayClick={false} style={gScriptWarningModalStyles}>
        {warningElement}
        <div className="d-flex" style={{ gap: 8 }}>
          <Button
            className="btn btn-primary"
            style={{ flex: 1 }}
            text="Cancel"
            onClick={() => setShowWarningModal(false)}
          />
          <Button className="btn btn-link" style={{ flex: 1 }} text="Proceed" onClick={handleGScriptSettingChange} />
        </div>
      </CustomModal>
      <form>
        <fieldset disabled={SHOPIFY_DISABLE_PRODUCT_EDIT}>
          <div>
            {SHOPIFY_DISABLE_PRODUCT_EDIT && (
              <Alert severity="warning" style={{ marginTop: '10px' }}>
                Product modifications are currently deactivated. Please utilise the Shopify platform for product edits,
                which will be reflected in our system upon synchronisation.
              </Alert>
            )}
            <h3 className="text-uppercase mx-autop-2 mt-4">{productId ? 'Product details' : 'Add New Product'}</h3>
            <div className="form-row mt-5">
              <FormInput
                type="text"
                value={data.name || ''}
                className="col-md-12"
                label="Name"
                name="name"
                onChange={handleUserInput('name')}
                error={errors.name}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.description || ''}
                className="col-md-12"
                label="Description"
                name="description"
                onChange={handleUserInput('description')}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label>Category</label>
                <Select
                  name="category"
                  isSearchable={true}
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  options={constants.ingredients}
                  onChange={handleIngredient}
                  value={constants.ingredients.find((x) => x.value === data.ingredient.value)}
                  className={errors.ingredient ? 'rounded dropdown-border w-100 px-0' : 'w-100'}
                />
                <div className="srv-validation-message">{errors.ingredient && errors.ingredient.props.children}</div>
              </div>
              <div className="form-group col-6">
                <label>Formulation</label>
                <Select
                  name="formulation"
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  isSearchable={true}
                  options={constants.formulations}
                  onChange={(e) => setData({ ...data, formulation: e })}
                  value={constants.formulations.find((x) => x.value === data.formulation.value)}
                  className={errors.formulation ? 'rounded dropdown-border w-100' : 'w-100 px-0'}
                />
                <div className="srv-validation-message">{errors.formulation && errors.formulation.props.children}</div>
              </div>
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.cbd_strength || ''}
                className="col-md-6"
                label="CBD Strength"
                name="cbdStrength"
                onChange={handleUserInput('cbd_strength')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.thc_strength || ''}
                className="col-md-6"
                label="THC Strength"
                name="thcStrength"
                onChange={handleUserInput('thc_strength')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.sku || ''}
                className="col-md-6"
                label="SKU"
                name="sku"
                onChange={handleUserInput('sku')}
                error={errors.sku}
              />
              <div className=" form-row col-md-6 prefixed-input">
                <span className={errors.price ? 'prefix error' : 'prefix'}>$</span>
                <FormInput
                  type="text"
                  value={data.price || ''}
                  className="col-md-12"
                  label="Price (AUD)"
                  name="price (AUD)"
                  onChange={handleUserInput('price')}
                  error={errors.price}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label>Supplier</label>
                <Select
                  name="Supplier"
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  isSearchable={true}
                  options={constants.suppliers}
                  onChange={(e) => setData({ ...data, supplier: e })}
                  value={constants.suppliers.find((x) => x.value === data.supplier.value)}
                  className="w-100 px-0"
                />
              </div>
              <FormInput
                type="text"
                value={data.cultivar || ''}
                className="col-md-6"
                label="Cultivar"
                name="cultivar"
                onChange={handleUserInput('cultivar')}
                error={errors.cultivar}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label>Strain</label>
                <Select
                  name="strain"
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  isSearchable={true}
                  options={constants.strains}
                  onChange={(e) => setData({ ...data, strain: e })}
                  value={constants.strains.find((x) => x.value === data.strain.value)}
                />
              </div>
              <FormInput
                type="number"
                value={data.thc_percentage || ''}
                className="col-md-6"
                label="THC %"
                name="thcPercentage"
                onChange={handleUserInput('thc_percentage')}
              />
            </div>
            <div className="form-row">
              <div className="form-group col-6">
                <label>Schedule</label>
                <Select
                  name="schedule"
                  isSearchable={true}
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  options={constants.schedules}
                  onChange={(e) => setData({ ...data, schedule: e })}
                  value={constants.schedules.find((x) => x.value === data.schedule.value)}
                  className={errors.schedule ? 'rounded dropdown-border w-100 px-0' : 'w-100'}
                />
                <div className="srv-validation-message">{errors.schedule && errors.schedule.props.children}</div>
              </div>
              <div className="form-group col-6">
                <label>Hybrid</label>
                <Select
                  name="formulation"
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  isSearchable={true}
                  options={constants.hybrids}
                  onChange={(e) => setData({ ...data, hybrid: e })}
                  value={constants.hybrids.find((x) => x.value === data.hybrid.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <FormInput
                type="number"
                value={data.interval}
                className="col-md-6"
                label="Interval"
                name="interval"
                onChange={(e) => setData({ ...data, interval: e.target.value, intervalLimit: e.target.value })}
                error={data.interval > 14 || data.interval < 1 ? errors.intervalLimit : errors.interval}
              />
              <div className="form-group col-6">
                <label>Spectrum</label>
                <Select
                  name="Spectrum"
                  isDisabled={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  isSearchable={true}
                  options={constants.spectrums}
                  onChange={(e) => setData({ ...data, spectrum: e })}
                  value={constants.spectrums.find((x) => x.value === data.spectrum.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <FormInput
                type="number"
                value={data.weight || ''}
                className="col-md-6"
                label="Weight (Kg)"
                name="weight"
                onChange={handleUserInput('weight')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.dosage}
                className="col-md-12"
                label="Dosage"
                name="interval"
                onChange={handleUserInput('dosage')}
              />
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="reasoningToggle" className="ml-3 checkbox">
                  Out of Stock - Reasoning
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="reasoningToggle"
                    className="custom-control-input"
                    id="reasoningToggle"
                    disabled={Boolean(!data.reasoningToggle && data.outOfStock)}
                    onChange={(event) => {
                      tryChangeGScriptingSetting(data, {
                        reasoningToggle: event.target.checked,
                        prop: 'reasoningToggle',
                      });
                    }}
                    checked={data.reasoningToggle}
                  />
                  <label htmlFor="reasoningToggle" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
              {data.reasoningToggle && (
                <div className="form-row col-6">
                  <FormInput
                    type="text"
                    id="reasoning"
                    value={data.reasoning}
                    placeholder="Reasoning"
                    className="col-md-12"
                    name="interval"
                    onChange={handleUserInput('reasoning')}
                  />
                  <AlternativeProductSelect
                    availableProducts={alternativeAvailableProducts}
                    selectedDefaultProducts={alternativeDefaultProducts}
                    setSelectedDefaultProducts={setAlternativeDefaultProducts}
                    shopifyDisableProductEdit={SHOPIFY_DISABLE_PRODUCT_EDIT}
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-active" className="ml-3 checkbox">
                  Active / In-Active
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-active"
                    className="custom-control-input"
                    id="product-active"
                    onChange={handleUserInput('active', 'boolean')}
                    checked={data.active}
                  />
                  <label htmlFor="product-active" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-active" className="ml-3 checkbox">
                  Available to all Doctors
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-available2all"
                    className="custom-control-input"
                    id="product-available2all"
                    onChange={handleUserInput('available2all', 'boolean')}
                    checked={data.available2all}
                  />
                  <label htmlFor="product-available2all" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-active" className="ml-3 checkbox">
                  Excluded from new prescriptions
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-isExcludedFromNewPrescriptions"
                    className="custom-control-input"
                    id="product-isExcludedFromNewPrescriptions"
                    onChange={handleUserInput('isExcludedFromNewPrescriptions', 'boolean')}
                    checked={data.isExcludedFromNewPrescriptions}
                  />
                  <label
                    htmlFor="product-isExcludedFromNewPrescriptions"
                    className="ml-3 checkbox custom-control-label"
                  ></label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-active" className="ml-3 checkbox">
                  Discontinued
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-isGenerativeScripting"
                    className="custom-control-input"
                    id="product-isGenerativeScripting"
                    onChange={(event) => {
                      tryChangeGScriptingSetting(data, {
                        isGenerativeScripting: event.target.checked,
                        prop: 'isGenerativeScripting',
                      });
                    }}
                    checked={data.isGenerativeScripting}
                  />
                  <label htmlFor="product-isGenerativeScripting" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
              {data.isGenerativeScripting && (
                <div className="form-row col-6 mt-3">
                  <AlternativeProductSelect
                    availableProducts={alternativeAvailableProducts}
                    selectedDefaultProducts={alternativeDefaultProducts}
                    setSelectedDefaultProducts={setAlternativeDefaultProducts}
                  />
                </div>
              )}
            </div>
            <div className="row d-flex">
              <h4 className="text-uppercase mx-autop-2 mt-4">Product Banners</h4>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-coming_soon" className="ml-3 checkbox">
                  Coming Soon
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-coming_soon"
                    className="custom-control-input"
                    id="product-coming_soon"
                    onChange={handleUserInput('coming_soon', 'boolean')}
                    checked={data.coming_soon}
                  />
                  <label htmlFor="product-coming_soon" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-new_product" className="ml-3 checkbox">
                  New Product
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-new_product"
                    className="custom-control-input"
                    id="product-new_product"
                    onChange={handleUserInput('new_product', 'boolean')}
                    checked={data.new_product}
                  />
                  <label htmlFor="product-new_product" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-4 mt-3">
                <label htmlFor="product-new_at_lowprice" className="ml-3 checkbox">
                  New low Price
                </label>
              </div>
              <div className="custom-control custom-switch">
                <div className="form-group col-2 mt-3">
                  <input
                    type="checkbox"
                    name="product-new_at_lowprice"
                    className="custom-control-input"
                    id="product-new_at_lowprice"
                    onChange={handleUserInput('new_at_lowprice', 'boolean')}
                    checked={data.new_at_lowprice}
                  />
                  <label htmlFor="product-new_at_lowprice" className="ml-3 checkbox custom-control-label"></label>
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <h4 className="text-uppercase mx-autop-2 mt-4">Patient Access Details</h4>
              <div className="form-row d-flex" style={{ flexDirection: 'row-reverse' }}>
                <div className="custom-control custom-switch">
                  <div className="form-group col-2 mt-4">
                    <input
                      type="checkbox"
                      name="patient-catalog-box"
                      className="custom-control-input"
                      id="patient-catalog-box"
                      onChange={handleUserInput('patient_catalog', 'boolean')}
                      checked={data.patient_catalog}
                    />
                    <label htmlFor="patient-catalog-box" className="ml-3 checkbox custom-control-label"></label>
                  </div>
                </div>
                <div className="mt-4 ml-5">
                  <label htmlFor="patient-catalog-box" className="ml-3 checkbox">
                    | Product available in Product Catalog
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.short_name || ''}
                className="col-md-6"
                label="Product Name (Simplified)"
                name="short_name"
                onChange={handleUserInput('short_name')}
                error={errors.short_name}
              />
              <FormInput
                type="text"
                value={data.size || ''}
                className="col-md-6"
                label="Volume"
                name="size"
                onChange={handleUserInput('size')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.description_for_display || ''}
                className="col-md-12"
                label="Description (For Display)"
                name="description_for_display"
                rows={3}
                onChange={handleUserInput('description_for_display')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.conditions || ''}
                className="col-md-6"
                label="Conditions"
                name="conditions"
                rows={5}
                onChange={handleUserInput('conditions')}
              />
              <FormInput
                type="text"
                value={data.contents || ''}
                className="col-md-6"
                label="Flavor/Carrier/Terpenes"
                name="contents"
                rows={5}
                onChange={handleUserInput('contents')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.how_to_use || ''}
                className="col-md-12"
                label="How to Use"
                name="how_to_use"
                rows={5}
                onChange={handleUserInput('how_to_use')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.cbd_strength_for_display || ''}
                className="col-md-6"
                label="CBD Strength (For Display)"
                name="cbd_strength_for_display"
                onChange={handleUserInput('cbd_strength_for_display')}
              />
              <FormInput
                type="text"
                value={data.thc_strength_for_display || ''}
                className="col-md-6"
                label="THC Strength (For Display)"
                name="thc_strength_for_display"
                onChange={handleUserInput('thc_strength_for_display')}
              />
            </div>
            <div className="form-row">
              <FormInput
                type="text"
                value={data.cbd_thc_ratio || ''}
                className="col-md-6"
                label="CBD:THC Ratio"
                name="cbd_thc_ratio"
                onChange={handleUserInput('cbd_thc_ratio')}
              />
            </div>
            <div className="form-row">
              <div className="form-group p-1 col-sm-2">
                <label>Upload Product Image</label>
                <small className="text-secondary d-block">(jpeg,png,jpg) are allowed</small>
                <div className="file-upload mt-2 p-2">
                  <label className="d-block" htmlFor="file-input">
                    <i className="fa fa-cloud-upload fa-5x" aria-hidden="true"></i>
                    <input id="file-input" name="productImage" className="d-none" type="file" onChange={handleFile} />
                    {(() => {
                      if (fileName === '' && fileError === '') {
                        return (
                          <React.Fragment>
                            <p className="mb-0 text-primary">Choose a file</p>
                            <small>File limit 5MB</small>
                          </React.Fragment>
                        );
                      } else if (fileError !== '') {
                        return <p className="text-danger p-1">{fileError}</p>;
                      } else {
                        return <p className="file-name">{fileName}</p>;
                      }
                    })()}
                  </label>
                </div>
              </div>
              <div className="col-sm-10">
                <img
                  src={getProductImageUrl(data.product_image, data.ProductImages)}
                  className="img-fluid mt-5"
                  width={150}
                />
              </div>
            </div>
            <Button
              text={productId ? 'Update Product' : 'Add Product'}
              type="submit"
              loading={submitInProgress}
              className="btn btn-primary btn-md ml-3 float-right"
              onClick={submit}
            />
          </div>
        </fieldset>
      </form>
      <CustomModal isOpen={showSplitModal} style={splitModalStyles} shouldCloseOnOverlayClick={false}>
        <CircleLoader style={{ margin: 0 }} />
      </CustomModal>
    </div>
  );
};

export default ProductEdit;
