import { DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import settings from '@/constants/constants';
import { FF_ENABLE_SUPER_ADMIN_INVENTORY } from '@/constants/featureFlags';
import { INVENTORY_STATUS } from '@/constants/inventory';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { AuthService } from '@/services/authentication.service';
import { addMultipleStockToInventory } from '@/services/pharmacist.service';
import type { InventoryUpdateProducts } from '@/types';

interface InventoryAdjustmentProps {
  handleClose: () => void;
  products: InventoryUpdateProducts[];
  pharmacyId: string;
  showAdjustmentModal: boolean;
  refetchProducts: () => void;
}

type FormValues = {
  products: InventoryUpdateProducts[];
};

const InventoryTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  verticalAlign: 'top'
}));

const ButtonGroup = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

function InventoryAdjustment({
  handleClose,
  products,
  pharmacyId,
  showAdjustmentModal,
  refetchProducts
}: InventoryAdjustmentProps) {
  const { flags } = useFeatureFlags();
  const roleId = AuthService.getUser()?.user?.role_id;

  const { control, handleSubmit, setValue, watch } = useForm<FormValues>({
    defaultValues: {
      products: [{ productId: '', name: '', stock: 0, typeId: '1', qty: 0, reason: '', newQty: 0 }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products'
  });

  const productOptions = useMemo(
    () =>
      products.map((product) => ({
        label: product.name,
        value: product.id
      })),
    [products]
  );

  const addRow = () => {
    append({
      id: 1,
      productId: '',
      name: '',
      stock: 0,
      typeId: '1',
      typeName: 'Increase',
      qty: 0,
      reason: '',
      newQty: 0
    });
  };

  const onSubmit = async (data: FormValues) => {
    try {
      const response = await addMultipleStockToInventory({
        inventory_products: data.products,
        pharmacy_id: pharmacyId
      });

      if (response.status === 200) {
        handleClose();
        refetchProducts();
        toast.success(response.data.message);
      }
    } catch {
      toast.error('Failed to adjust inventory');
    }
  };

  const renderSelectOptions = () => {
    const options = [{ value: '1', label: 'Increase' }];
    if (!flags[FF_ENABLE_SUPER_ADMIN_INVENTORY] || roleId === USER_ROLES.superAdmin) {
      options.push({ value: '2', label: 'Decrease' });
    }
    return options;
  };

  return (
    <Dialog open={showAdjustmentModal} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Product Adjustments</DialogTitle>
      <DialogContent>
        <Typography paddingBottom={8}>
          {roleId === USER_ROLES.superAdmin || !flags[FF_ENABLE_SUPER_ADMIN_INVENTORY]
            ? 'Increase or decrease the available products'
            : 'Increase the available products'}
        </Typography>
        <Box component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
          <Table>
            <TableHead>
              <TableRow>
                <InventoryTableCell sx={{ paddingLeft: 0 }} width="25%">
                  Product
                </InventoryTableCell>
                <InventoryTableCell width="12%">Warehouse Stock</InventoryTableCell>
                <InventoryTableCell>Adjustment Type</InventoryTableCell>
                <InventoryTableCell width="10%">Quantity</InventoryTableCell>
                <InventoryTableCell>Reason for Adjustment</InventoryTableCell>
                <InventoryTableCell width="12%">Revised Quantity</InventoryTableCell>
                <InventoryTableCell className="text-center">Remove</InventoryTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((row, index) => (
                <TableRow key={row.id}>
                  <InventoryTableCell sx={{ paddingLeft: 0 }} width="20%">
                    <Controller
                      name={`products.${index}.productId`}
                      control={control}
                      rules={{
                        required: 'Select a product'
                      }}
                      render={({ field: productField, fieldState: { error } }) => {
                        const currentRow = watch(`products.${index}`);

                        return (
                          <Autocomplete
                            options={productOptions}
                            getOptionLabel={(option) => option.label}
                            data-testid="product-select"
                            onChange={(_, selectedProduct) => {
                              const product = products.find((p) => p.id === selectedProduct?.value);

                              setValue(`products.${index}.name`, product?.name || '');
                              setValue(`products.${index}.stock`, product?.stock || 0);

                              const calculatedNewQty =
                                Number(currentRow.typeId) === INVENTORY_STATUS.INCREMENT
                                  ? (product?.stock || 0) + Number(currentRow.qty)
                                  : (product?.stock || 0) - Number(currentRow.qty);

                              setValue(`products.${index}.newQty`, calculatedNewQty);

                              productField.onChange(selectedProduct?.value);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} size="small" error={!!error} helperText={error?.message} />
                            )}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            renderOption={(props, option) => (
                              <ListItem {...props} key={option.value}>
                                {option.label}
                              </ListItem>
                            )}
                          />
                        );
                      }}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell width="12%">
                    <Controller
                      name={`products.${index}.stock`}
                      control={control}
                      render={({ field }) => (
                        <TextField {...field} size="small" data-testid="warehouse-stock" disabled />
                      )}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell width="12%">
                    <Controller
                      name={`products.${index}.typeId`}
                      control={control}
                      rules={{
                        required: 'Select an adjustment type'
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const currentRow = watch(`products.${index}`);

                        return (
                          <>
                            <Select
                              {...field}
                              onChange={(event) => {
                                const selectedAdjustment = event.target.value;
                                const updatedAdjustmentType =
                                  selectedAdjustment === '1' ? INVENTORY_STATUS.INCREMENT : INVENTORY_STATUS.DECREMENT;

                                const calculatedNewQty =
                                  updatedAdjustmentType === INVENTORY_STATUS.INCREMENT
                                    ? (currentRow?.stock || 0) + Number(currentRow.qty)
                                    : (currentRow?.stock || 0) - Number(currentRow.qty);

                                setValue(`products.${index}.newQty`, calculatedNewQty);

                                field.onChange(selectedAdjustment);
                              }}
                              error={!!error}
                              fullWidth
                              size="small"
                            >
                              {renderSelectOptions().map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </>
                        );
                      }}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell width="10%">
                    <Controller
                      name={`products.${index}.qty`}
                      control={control}
                      rules={{
                        required: 'Quantity is required',
                        validate: (value: string | number) => Number(value) > 0 || 'Quantity must be greater than 0'
                      }}
                      render={({ field, fieldState: { error } }) => {
                        const currentRow = watch(`products.${index}`);

                        return (
                          <TextField
                            {...field}
                            type="number"
                            onChange={(event) => {
                              const newQty = Number(event.target.value);

                              const updatedRow = {
                                ...currentRow,
                                qty: newQty
                              };

                              const calculatedNewQty =
                                updatedRow.typeId === settings.inventoryStatus.INCREMENT
                                  ? (updatedRow?.stock || 0) + newQty
                                  : (updatedRow?.stock || 0) - newQty;

                              setValue(`products.${index}.newQty`, calculatedNewQty);

                              field.onChange(newQty);
                            }}
                            fullWidth
                            size="small"
                            data-testid="quantity"
                            error={!!error}
                            helperText={error?.message}
                          />
                        );
                      }}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell>
                    <Controller
                      name={`products.${index}.reason`}
                      control={control}
                      rules={{
                        required: 'Reason for the adjustment'
                      }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                          data-testid="reason-for-adjustment"
                        />
                      )}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell width="12%">
                    <Controller
                      name={`products.${index}.newQty`}
                      rules={{
                        validate: (value: string | number) => Number(value) >= 0 || 'Negative value not allowed'
                      }}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          size="small"
                          error={!!error}
                          helperText={error?.message}
                          data-testid="revised-quantity"
                          disabled
                        />
                      )}
                    />
                  </InventoryTableCell>
                  <InventoryTableCell>
                    <IconButton data-testid="remove-row" onClick={() => remove(index)}>
                      <DeleteOutline />
                    </IconButton>
                  </InventoryTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            sx={{
              mt: 2
            }}
            onClick={addRow}
          >
            Add Row
          </Button>
          <ButtonGroup>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton type="submit" variant="contained" loading={false}>
              Confirm
            </LoadingButton>
          </ButtonGroup>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default InventoryAdjustment;
