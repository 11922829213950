import React, { Component } from 'react';
import axios from 'axios';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import FilterableTable from 'rc-data-table';
import settings from '../../constants/constants';
import CircleLoader from '../common/circleLoader';
import withRouter from '@/utils/withRouter';

const url = settings.url;

class summaryCreditView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creditSummaryData: [],
      sort: { name: 'patientName', reverse: false },
      page: 0,
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.dataBindCreditSummary();
  }

  //Get all patient summary credit from api
  dataBindCreditSummary = async () => {
    await axios.get(`${url}/patientcredit/summary`).then((response) => {
      this.setState({ creditSummaryData: response.data.result, count: response.data.count, loading: false });
    });
  };

  pageChange = (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (filter) => {
    this.setState({ filter, page: 0 }, function () {
      this.dataBind();
    });
  };

  render() {
    const { loading, creditSummaryData, pageSize, page, sort, count, filter } = this.state;

    const summaryFields = [
      {
        name: 'patientId',
        displayName: 'Patient ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'patientName',
        displayName: 'Patient Name',
        inputFilterable: true,
        tdClassName: 'sub-text w-15',
        sortable: false,
      },
      {
        name: 'totalcredit',
        displayName: 'Total Credit',
        inputFilterable: true,
        tdClassName: 'sub-text w-15',
        sortable: false,
      },
      {
        name: 'consumedcredit',
        displayName: 'Consumed Credit',
        inputFilterable: true,
        tdClassName: 'sub-text w-15',
        sortable: false,
      },
      {
        name: 'remainingcredit',
        displayName: 'Remaining Credit',
        inputFilterable: true,
        tdClassName: 'sub-text w-15',
        sortable: false,
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <h3 style={{ padding: '20px' }}>Credit Summary</h3>
          <div>
            {loading ? (
              <CircleLoader />
            ) : (
              <FilterableTable
                className="table-responsive w-100 p-2"
                trClassName={this.preferredPharmacyCheck}
                data={creditSummaryData}
                loading={loading}
                fields={summaryFields}
                paginationOnServer={true}
                initialSort={sort.patientName}
                initialSortDir={!sort.reverse}
                onPageChanged={this.pageChange}
                filterData={this.onFilter}
                serverSort={this.getSortColumn}
                totalRecords={count}
                currentPage={page}
                pageSize={pageSize}
                filterPlaceholder={filter ? filter : 'Search'}
                pageSizes={false}
                maintainPageOnSetData={true}
                topPagerVisible={false}
                noRecordsMessage="There are no record to display"
                noFilteredRecordsMessage="No record match your filters!"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(summaryCreditView);
