import { Table } from '@montugroup/design-system';
import { Box, Button, Modal, styled, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { PharmacyService } from '@/services/pharmacy.service';
import type { UpdateOrderPharmacyModalProps, UpdatePharmacy } from '@/types/pharmacyView.types';

const columns = [
  {
    header: 'Order Code',
    accessorKey: 'orderCode'
  },
  {
    header: 'From Pharmacy',
    accessorKey: 'fromPharmacy'
  },
  {
    header: 'To Pharmacy',
    accessorKey: 'toPharmacy'
  },
  {
    header: 'Repeated Order',
    accessorKey: 'repeated'
  },
  {
    header: 'Eligible for Update',
    accessorKey: 'eligible'
  }
];

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  background: theme.palette.common.white,
  margin: '10% auto',
  width: '80%'
}));

const CustomisedBox = styled(Box)(({ theme }) => ({
  overflowX: 'auto',
  marginTop: theme.spacing(4)
}));

const BottomContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}));

const ColorCode = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

export const useUpdatePharmacy = (onClose: () => void) => {
  const mutation = useMutation({
    mutationFn: PharmacyService.updatePharmacy,
    onSuccess: () => {
      toast.success('Pharmacies Updated Successfully');
      onClose();
    },
    onError: () => {
      toast.error('Error while updating Pharmacies');
    }
  });
  return mutation;
};

export function UpdateOrderPharmacyModal({
  isOpen,
  onClose,
  SaveOrderUpdateData,
  pharmacyFileName
}: UpdateOrderPharmacyModalProps) {
  const mutation = useUpdatePharmacy(onClose);

  const saveOrderUpdateModal = () => {
    const updateData = SaveOrderUpdateData.filter(
      (od) => (od.hasePrescription && od.isEligible) || (!od.isRepeated && od.isEligible) || od.both
    ) as UpdatePharmacy[];

    mutation.mutate({
      updateData,
      fileName: pharmacyFileName
    });
  };

  const cancelOrderUpdateModal = () => {
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={cancelOrderUpdateModal} sx={{ overflow: 'auto' }}>
      <Container>
        <Typography component="h3" variant="h5">
          Update Order Pharmacy
        </Typography>
        <CustomisedBox>
          <Table data={SaveOrderUpdateData || []} columns={columns} />
        </CustomisedBox>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginLeft: 'auto', marginRight: '12px' }}
            onClick={saveOrderUpdateModal}
          >
            Update Pharmacies
          </Button>
          <Button variant="outlined" onClick={cancelOrderUpdateModal}>
            Cancel
          </Button>
        </ButtonContainer>
        <BottomContainer>
          <Typography>
            <b>Color Code Guide:</b>
          </Typography>
          <ColorCode>
            <Typography variant="body1" component="ul" sx={{ color: 'black' }}>
              Black
            </Typography>
            <Typography variant="body1" component="p">
              : Order can be reassigned.
            </Typography>
          </ColorCode>
          <ColorCode>
            <Typography variant="body1" component="ul" sx={{ color: 'red' }}>
              Red
            </Typography>
            <Typography variant="body1" component="p">
              : Repeated Paper Script - Order cannot be reassigned.
            </Typography>
          </ColorCode>
          <ColorCode>
            <Typography variant="body1" component="ul" sx={{ color: '#A3B1B8' }}>
              Grey
            </Typography>
            <Typography variant="body1" component="p">
              : The Pharmacy selected to update is a Partner Pharmacy - The order cannot be reassigned.
            </Typography>
          </ColorCode>
        </BottomContainer>
      </Container>
    </Modal>
  );
}

export default UpdateOrderPharmacyModal;
