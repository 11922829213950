import { Button } from '@montugroup/design-system';
import { Box, Stack, styled } from '@mui/material';
import axios from 'axios';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

import ExportAllDateRangeButton from '@/components/common/ExportAllDateRangeButton';
import FilterByOrderStatusSelect from '@/components/common/FilterByOrderStatusSelect';
import FilterOrderByPharmacySelect from '@/components/common/FilterOrderByPharmacySelect';
import { OrderStatus } from '@/components/order/order.types';
import BulkActions from '@/components/orders/bulkActions/BulkActions';
import settings from '@/constants/constants';
import { useOrders } from '@/context/orders/Orders';
import { useExportAndShipAwaitingModal } from '@/context/orders/OrdersModals';
import AuthService from '@/services/authentication.service';

const url = settings.url;

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
  alignItems: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
}));

function OrdersActions() {
  const { statusFilters, setStatusFilters, pharmacyIds, setPharmacyIds, toggleSelectAllOrders } = useOrders();
  const { showExportAndShipAwaitingModal } = useExportAndShipAwaitingModal();

  const isSuperAdmin = AuthService.isSuperAdmin();

  const downloadFile = async (from: string | null = null, to: string | null = null) => {
    const response = await axios.post(`${url}/excel/order/download`, {
      data: {
        ...(from ? { from_date: from } : {}),
        ...(to ? { to_date: to } : {})
      }
    });

    toast.success(response.data.message);
  };

  const exportAll = (fromDate: string, toDate: string) => {
    downloadFile(fromDate, toDate);
  };

  const exportAllAdmin = () => {
    downloadFile(
      moment().subtract(1, 'months').format('YYYY-MM-DD').toString(),
      moment().format('YYYY-MM-DD').toString()
    );
  };

  const handlePharmacySelect = (ids: string[] | null) => {
    setPharmacyIds(ids ?? []);
  };

  if (!AuthService.isAdmin()) {
    return null;
  }

  return (
    <Stack direction="column" spacing={2}>
      <ActionsContainer>
        <Button variant="contained" size="small" onClick={showExportAndShipAwaitingModal}>
          Export & Ship All Awaiting
        </Button>
        {statusFilters.length === 1 && statusFilters[0].name === OrderStatus.AwaitingShipment && (
          <Button size="small" color="info" onClick={toggleSelectAllOrders}>
            Select all
          </Button>
        )}
        {isSuperAdmin && <ExportAllDateRangeButton buttonProps={{ size: 'small' }} onExport={exportAll} />}
        {!isSuperAdmin && (
          <Button size="small" color="info" onClick={exportAllAdmin}>
            Export
          </Button>
        )}
        <BulkActions />
      </ActionsContainer>
      <ActionsContainer>
        <FilterByOrderStatusSelect buttonSize="small" onSelect={setStatusFilters} selectedStatuses={statusFilters} />
        <FilterOrderByPharmacySelect
          buttonSize="small"
          onSelect={handlePharmacySelect}
          selectedPharmacies={pharmacyIds}
        />
      </ActionsContainer>
    </Stack>
  );
}

export default OrdersActions;
