import { ArrowBack, ArrowUpward } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useCreatePatientProfileMerge } from './hooks/useCreatePatientProfileMerge';
import { useGetSecondaryProfiles } from './hooks/useGetSecondaryProfiles';
import MergePatientModalHeader from './mergePatientModalHeader';
import PrimaryProfile from './primaryProfile';
import SecondaryProfiles from './secondaryProfiles';

const GridParentContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '100%',
  minHeight: '250px',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: '40% 20% 40%'
  }
}));

interface IMergePatientModal {
  onClick: () => void;
  open: boolean;
}

function MergePatientModal({ onClick, open }: IMergePatientModal) {
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('md'));
  const [patientCode, setPatientCode] = useState('');
  const [isValidPatientCode, setIsValidPatientCode] = useState(true);

  const { data, isLoading, refetch, isError, error } = useGetSecondaryProfiles(patientCode);
  const {
    createPatientProfileMerge,
    isPending: mergeIsPending,
    isSuccess: mergeIsSuccess,
    isError: mergeIsError
  } = useCreatePatientProfileMerge();

  useEffect(() => {
    if (mergeIsSuccess) {
      toast.success('Profiles Successfully Merged');
    }
  }, [mergeIsSuccess]);

  const handleOnMergeProfileClick = async () => {
    if (!data) {
      return;
    }

    const fromPatientCodes = data.duplicates.map((patient: { patientCode: string }) => patient.patientCode);
    await createPatientProfileMerge({ fromPatientCodes, toPatientCode: patientCode });
    setPatientCode('');
  };

  return (
    <Dialog
      open={open}
      onClose={onClick}
      aria-labelledby="merge-patient-modal"
      aria-describedby="modal used to merge patient profiles"
    >
      <Box padding="2rem">
        <MergePatientModalHeader onClick={onClick} />
        <DialogContent>
          <GridParentContainer>
            <PrimaryProfile
              patientCode={patientCode}
              setPatientCode={setPatientCode}
              setIsValidPatientCode={setIsValidPatientCode}
            />
            <Box display="flex" justifyContent="center" alignItems="center">
              {isLargeView ? (
                <ArrowBack />
              ) : (
                <ArrowUpward
                  sx={{
                    marginY: '1rem'
                  }}
                />
              )}
            </Box>
            <SecondaryProfiles
              isLoading={isLoading}
              isError={isError}
              errorMessage={error?.message || 'Error retrieving secondary profiles'}
              isValidPatientCode={isValidPatientCode}
              patientCode={patientCode}
              onSecondaryButtonClick={() => refetch()}
              data={data}
            />
          </GridParentContainer>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="secondary"
            onClick={onClick}
            sx={{
              margin: '1rem 0.25rem 1rem 0'
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            variant="contained"
            onClick={handleOnMergeProfileClick}
            loading={mergeIsPending}
            sx={{
              margin: '1rem 0'
            }}
            disabled={!data}
          >
            Merge Profiles
          </LoadingButton>
        </DialogActions>

        {mergeIsError && <Alert severity="error">Error merging secondary profiles</Alert>}
      </Box>
    </Dialog>
  );
}

export default MergePatientModal;
