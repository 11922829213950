import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

const DEFAULT_ALLOWED_FILE_TYPES = ['xls', 'xlsx'];
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5242880;

interface UseFileHandlingProps {
  onUpload: (formData: FormData) => Promise<void>;
  allowedFileTypes?: string[];
  maxFileSizeInBytes?: number;
  onUploadSuccess?: () => void;
}

const useFileHandling = ({
  onUpload,
  allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  onUploadSuccess
}: UseFileHandlingProps) => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (!selectedFile) {
      return;
    }

    const fileName = selectedFile.name;
    const fileSize = selectedFile.size;
    const fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();

    if (!allowedFileTypes.includes(fileExtension || '')) {
      toast.error(`Invalid file type ${fileExtension} `);
      return;
    }

    if (fileSize > maxFileSizeInBytes) {
      toast.error(`Upload file error: File size exceeded: ${fileSize}`);
      return;
    }

    setFile(selectedFile);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      await onUpload(formData);
      toast.success('Pharmacy uploaded successfully');
      onUploadSuccess?.();
    } catch (err) {
      toast.error(`Upload file error: Upload failed: ${err}`);
    }
  };

  return {
    file,
    handleFileChange
  };
};

export default useFileHandling;
