import { HttpStatus } from '@montugroup/apis-styleguide';
import { getPatientRecommendedDevices } from '@montugroup/circuit-api-contracts';
import { useQuery } from '@tanstack/react-query';
import { TsRestResponseError } from '@ts-rest/core';
import type { z } from 'zod';

import { getTsCustomerPortalClient } from '@/api/customer-portal.client';

import type { AsyncContent, RecommendedDevice } from '../patient/refill/types';

const client = getTsCustomerPortalClient();

export type RecommendedDeviceResponse = z.infer<(typeof getPatientRecommendedDevices.responses)['200']>['data'];

interface UseGetRecommendedDevicesOptions {
  onError?: (err: unknown) => void;
  enabled: boolean;
}

const QUERY_KEY_RECOMMENDED_DEVICES = 'recommendedDevices';

export const useGetRecommendedDevices = (
  userId: number,
  options: UseGetRecommendedDevicesOptions
): AsyncContent<RecommendedDevice[]> => {
  const { enabled, onError } = options;

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: [QUERY_KEY_RECOMMENDED_DEVICES, userId],
    queryFn: async () => {
      const res = await client.getPatientRecommendedDevices.query();
      if (res.status !== HttpStatus.OK) {
        throw new TsRestResponseError(getPatientRecommendedDevices, res);
      }

      return res.body.data.products.map((x) => ({
        id: x.id,
        price: x.price,
        productName: x.name,
        supplier: x.supplierName
      }));
    },
    enabled,
    meta: {
      onError
    }
  });

  // If enable_recommended_devices feature flag is disabled, return an empty list, so the section is hidden
  if (!enabled) {
    return {
      data: [],
      isError: false,
      isLoading: false,
      isSuccess: true
    };
  }

  return {
    data,
    isError,
    isLoading,
    isSuccess
  };
};
