import {
  AccountCircleRounded as AccountCircleRoundedIcon,
  Close as CloseIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  backdropClasses,
  menuClasses,
  styled
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import AlternaleafLogo from '@/components/common/AlternaleafLogo';
import MontuLogo from '@/components/common/MontuLogo';
import { mainMenu, userMenu } from '@/components/layout/navbar/MenuItems';
import { teal } from '@/components/products/detail/common';
import { FF_ENABLE_WOM_CAMPAIGN } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { BRANDS } from '@/types';

import { AuthService } from '../../services/authentication.service';
import PatientReferralView from '../patientReferral/patientReferralView';

import '../dashboard/dashboard.scss';

const NavigationBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  height: theme.mixins.toolbar.height,
  width: '100%'
}));

const NavToolbar = styled(Toolbar)(({ theme }) => ({
  padding: '0 1.5rem',
  justifyContent: 'space-between',
  gap: '2rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '0 10rem'
  }
}));

const MenuIconContainer = styled(Box)`
  display: flex;
`;

const UserMenuContainer = styled(Menu)(({ theme }) => ({
  [`.${menuClasses.paper}`]: {
    minWidth: '10rem',
    backgroundColor: theme.palette.background.default
  }
}));

const MainMenuContainer = styled(Menu)(({ theme }) => ({
  [`.${menuClasses.paper}`]: {
    width: '80%',
    maxWidth: '20rem',
    height: '100%',
    maxHeight: '100%',
    right: 0,
    bottom: 0,
    left: 'unset !important',
    top: 'unset !important',
    backgroundColor: `${teal}`,
    borderRadius: 0,
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - (${theme.mixins.toolbar.height} - 30px))`,
      width: '25vw',
      maxWidth: '100%',
      minWidth: '20rem'
    },
    '> ul': {
      height: '100%',
      padding: 0
    }
  },
  [`.${backdropClasses.root}`]: {
    backgroundColor: theme.palette.common.black,
    opacity: '0.8 !important',
    [theme.breakpoints.up('md')]: {
      top: 'unset',
      height: `calc(100vh - ${theme.mixins.toolbar.height})`
    }
  }
}));

const AlternaleafMenuContainer = styled(Box)(({ theme }) => ({
  padding: '0 0 1rem',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.background.default
}));

const MontuMenuContainer = styled(Box)(({ theme }) => ({
  padding: '0 0 1rem',
  backgroundColor: `${teal}`,
  color: theme.palette.common.white
}));

const UserMenuItemsWrapper = styled(Box)(({ theme }) => ({
  '> li': {
    padding: 0,
    color: theme.palette.primary.main,
    '> a': {
      padding: '0.75rem 1.5rem',
      color: 'inherit',
      width: '100%',
      '&:hover': {
        textDecoration: 'none'
      }
    }
  }
}));

const MainMenuItemsWrapper = styled(Box)`
  padding-top: 1rem;

  > li {
    padding: 0;
    color: inherit;

    > a {
      padding: 0.75rem 1.5rem;
      color: inherit;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.up('lg')]: {
    '&:first-of-type': {
      marginRight: '1.5rem'
    },
    '&:only-of-type': {
      margin: 0
    },
    '&:focus': {
      outline: 'none'
    }
  }
}));

const CloseIconContainer = styled(Box)(({ theme }) => ({
  padding: '1rem 1.5rem',
  width: '100%',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start'
  },

  '> button': {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  padding: '1rem 1.5rem',
  width: '100%',
  backgroundColor: theme.palette.background.default
}));

const MontuFooter = styled(Typography)`
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  line-height: 2;
  padding: 1rem 1.5rem;
`;

function Navbar() {
  const { flags } = useFeatureFlags();
  const roleId = AuthService.getUser()?.user?.role_id || USER_ROLES.doctor;

  const userMenuItems = userMenu.filter((userMenuItem) => userMenuItem.roleId.includes(roleId));
  const mainMenuItemsAlternaleaf = mainMenu.filter(
    (menuItem) => menuItem.brand === BRANDS.ALTERNALEAF && menuItem.roleId.includes(roleId)
  );
  const mainMenuItemsMontu = mainMenu.filter(
    (menuItem) => menuItem.brand === BRANDS.MONTU && menuItem.roleId.includes(roleId)
  );

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <NavigationBar>
      <NavToolbar disableGutters>
        <Link to={roleId === USER_ROLES.patient ? '/home' : 'https://www.montu.com.au'}>
          <AlternaleafLogo />
        </Link>

        <MenuIconContainer>
          <StyledIconButton size="large" aria-haspopup="true" onClick={handleOpenUserMenu}>
            <AccountCircleRoundedIcon />
          </StyledIconButton>

          <UserMenuContainer
            data-dd-privacy="allow"
            id="user-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <UserMenuItemsWrapper>
              {userMenuItems.map((profileMenuItem) => (
                <MenuItem key={profileMenuItem.id} onClick={handleCloseUserMenu}>
                  <Link to={profileMenuItem.link}>{profileMenuItem.name}</Link>
                </MenuItem>
              ))}
            </UserMenuItemsWrapper>
          </UserMenuContainer>

          <StyledIconButton size="large" aria-haspopup="true" onClick={handleOpenNavMenu}>
            <MenuIcon />
          </StyledIconButton>

          <MainMenuContainer
            data-dd-privacy="allow"
            id="menu-appbar"
            anchorEl={anchorElNav}
            marginThreshold={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {flags[FF_ENABLE_WOM_CAMPAIGN] && roleId === USER_ROLES.patient && <PatientReferralView isNavBanner />}
            <CloseIconContainer>
              <StyledIconButton size="large" aria-haspopup="true" onClick={handleCloseNavMenu}>
                <CloseIcon />
              </StyledIconButton>
            </CloseIconContainer>

            <AlternaleafMenuContainer>
              <LogoContainer>
                <AlternaleafLogo />
              </LogoContainer>

              <MainMenuItemsWrapper>
                {mainMenuItemsAlternaleaf.map((mainMenuItem) => (
                  <MenuItem key={mainMenuItem.id} onClick={handleCloseNavMenu}>
                    <Link to={mainMenuItem.link}>{mainMenuItem.name}</Link>
                  </MenuItem>
                ))}
              </MainMenuItemsWrapper>
            </AlternaleafMenuContainer>

            <MontuMenuContainer>
              <MainMenuItemsWrapper>
                {mainMenuItemsMontu.map((mainMenuItem) => (
                  <MenuItem key={mainMenuItem.id} onClick={handleCloseNavMenu}>
                    <Link to={mainMenuItem.link}>{mainMenuItem.name}</Link>
                  </MenuItem>
                ))}
              </MainMenuItemsWrapper>

              <MontuFooter>
                powered by
                <MontuLogo />
              </MontuFooter>
            </MontuMenuContainer>
          </MainMenuContainer>
        </MenuIconContainer>
      </NavToolbar>
    </NavigationBar>
  );
}

export default Navbar;
