import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { UserService } from '@/services/user.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useUserProfileData');

export const useUserProfileData = () => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['userProfileData'],

    queryFn: async () => {
      try {
        const response = await UserService.fetchUserProfile();
        if (response.data) {
          return response.data;
        }
        throw new Error('failed to fetch data');
      } catch (e) {
        logger.error('Error fetching user profile, error:', e);
        throw e;
      }
    }
  });

  const getUserState = useCallback(() => {
    if (!data || !data.states || !data.user) {
      return null;
    }

    const userState = data.states.find((state: { id: number }) => data.user.state_id === state.id);

    return userState ? userState.name : null;
  }, [data]);

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    getUserState
  };
};

export default useUserProfileData;
